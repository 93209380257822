<div class="job-details-block mt-4">
    <div class="jobs-section">
        <div class="sub-header" *ngIf="!isQuoteEditPage()">Job Details</div>
        <div class="sub-header" *ngIf="isQuoteEditPage()">Save Job</div>
        <app-create-job [isOrderPlaced]="isOrderPlaced"></app-create-job>
    </div>
    <div [ngClass]="{'margin-top-12': isQuoteEditPage()}" class="vertical-row"></div>

    <div [ngClass]="{'margin-top-12': isQuoteEditPage()}" class="quote-edit-section" *ngIf="isQuoteEditPage()">
        <app-create-revision></app-create-revision>
    </div>

    <div class="quote-section mt-3"  *ngIf="!isQuoteEditPage()">
        <app-revision class="row mb-2 ml-1 table-responsive"
            [isOrderPlaced]="isOrderPlaced"></app-revision>
    </div>
</div>