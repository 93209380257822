import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { environment } from '@cpq-environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export enum Personas {
  DISTRIBUTOR = 'distributor'
}

export enum SessionVariables {
  CUSTOMER_VIEW = 'isCustomerViewOn',
  PERSONA = 'persona',
  DISABLE_3D = 'disable3d',
  USER_TYPE = 'userType'
}

@Injectable(
  { providedIn: 'root' }
)
export class UsersService {
  constructor(private http: HttpClient, private authService: MSALService) { }

  private backendUrl = environment.B2CConfigs.BackendURL;
  private cxbackendUrl = environment.cxPortal.baseUrl;
  private customerView: BehaviorSubject<boolean>;
  private enable3d: BehaviorSubject<boolean>;
  private userLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getData = () => {
    return this.http.get(`${this.backendUrl}/users/all`);
  }

  getAllActiveUsers = () => {
    return this.http.get(`${this.backendUrl}/users/active`);
  }

  createUserData = (formdata) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.post(`${this.backendUrl}/users/createUser`, formdata, {
      headers: customHttpHeader,
    });
  }

  getUserById = (id: any) => {
    return this.http.get(`${this.backendUrl}/users/getUserById/${id}`);
  }

  deleteUser = (id: any) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.delete(`${this.backendUrl}/users/deleteUserById/${id}`, {
      headers: customHttpHeader,
    });
  }

  EditUser = (formdata, id: any) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put(
      `${this.backendUrl}/users/updateUserById/${id}`,
      formdata,
      { headers: customHttpHeader }
    );
  }

  EditProfile = (userObj) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(
      `${this.backendUrl}/users/profile`,
      userObj,
      { headers: customHttpHeader }
    );
  }

  createUserInAD = (data) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    return this.http.post(`${this.backendUrl}/b2c/createuser`, data, {
      headers: customHttpHeader,
    });
  }

  checkUserExits = (userName: any) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    return this.http.get(`${this.backendUrl}/b2c/checkuser/${userName}`, {
      headers: customHttpHeader,
    });
  }

  checkAccess = (userName: any) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    return this.http.get(
      `${this.backendUrl}/b2c/checkaccess/Platform/${userName}`,
      { headers: customHttpHeader }
    );
  }

  addapplication = (data) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    return this.http.post(`${this.backendUrl}/b2c/addapplication`, data, {
      headers: customHttpHeader,
    });
  }

  deleteUserInAD = (data) => {
    const aud = this.authService.getAudience();
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      aud,
    });
    return this.http.post(`${this.backendUrl}/b2c/deleteuser`, data, {
      headers: customHttpHeader,
    });
  }

  getUser(oid: string): Observable<Object> { // FIXME this should return a specific type
    const customHttpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      })
    };
    return this.http.get(`${this.backendUrl}/users/oid/${oid}`, customHttpHeader);  // FIXME services should not return HTTP objects; they should instead digest and return the object or the appropriate error signal
  }

  updatePrivacyTerms(data) {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.put(
      `${this.backendUrl}/users/updatePrivacyStatus`,
      data,
      { headers: customHttpHeader }
    );
  }

  getCustomerView(): Observable<boolean> {
    if (!this.customerView) {
      // SessionStorage only stores strings, so we must convert back to boolean
      const rawValue = sessionStorage.getItem(SessionVariables.CUSTOMER_VIEW);
      const active = Boolean(JSON.parse(rawValue) || false).valueOf();
      this.customerView = new BehaviorSubject<boolean>(active);
    }

    return this.customerView;
  }

  get3DView(): Observable<boolean> {
    if (!this.enable3d) {
      // SessionStorage only stores strings, so we must convert back to boolean
      const rawValue = sessionStorage.getItem(SessionVariables.DISABLE_3D) || 'false';
      const disabled = Boolean(JSON.parse(rawValue) || false).valueOf();
      this.enable3d = new BehaviorSubject<boolean>(!disabled);
    }
    return this.enable3d;
  }

  getUserLoadedSub(): Observable<boolean> {
    return this.userLoaded;
  }

  setUserLoadedSub() {
    this.userLoaded.next(true);
  }

  setCustomerViewStatus(isActive: boolean): void {
    // SessionStorage only stores strings
    sessionStorage.setItem(SessionVariables.CUSTOMER_VIEW, String(isActive || false));
    this.customerView.next(isActive);
  }

  set3DViewStatus(enabled = true): void {
    // SessionStorage only stores strings
    sessionStorage.setItem(SessionVariables.DISABLE_3D, String(!enabled));
    this.enable3d.next(enabled);
  }

  checkLoggedInUserIsDistributor(): boolean {
    return sessionStorage.getItem(SessionVariables.USER_TYPE) === Personas.DISTRIBUTOR;
  }

  getUserByUsernameFromUI = (username: string) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.cxbackendUrl}/api/users/vws/get-userby-username/${username}`, {
      headers: customHttpHeader,
    });
  };

  getCXPortalToken = () => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.backendUrl}/users/getCXPortalToken`, {
      headers: customHttpHeader,
    });
  }

  updateCpqUsernameInB2C = (username: string, cpqUsername: string) => {
    const customHttpHeader = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get(`${this.cxbackendUrl}/api/users/updateCPQUserName/vws/${username}/cpqUserName/${cpqUsername}`, {
      headers: customHttpHeader,
    });
  }

  getLoggedInUserType() {
    return sessionStorage.getItem(SessionVariables.USER_TYPE);
  }
}
