import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, take } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { LogoutService } from './logoutService';
import { environment } from '@cpq-environments/environment';
import { StatusCodes as HttpStatus } from 'http-status-codes';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private logoutService: LogoutService,
    private toastr: ToastrService
  ) {
    this.toastr.toastrConfig.preventDuplicates = true;
  }

  readonly BROWSER_REQUEST_ERROR = 0;

  readonly TOASTR = {
    TEXT: {
      WARNING_TITLE: `Uh oh...`,
      ERROR_TITLE: `We're sorry...`,
      MSG: {
        BAD_REQUEST: `The system has encountered an error; please try again.`,
        NOT_FOUND: `The requested feature or item was not found.`,
        INTERNAL_SERVER_ERROR: `The system has encountered an error; please try again.`,
        FORBIDDEN: `You do not have permission to access this feature.`,
        UNAUTHORIZED: `Your Session is expired or is unauthorized. You will need to login again.`,
      }
    },
    VALUE: {
      WARNING_FADE_TIMEOUT: 5000, // in mS
    },
  };

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token: string;
    if(sessionStorage.getItem('msal.idtoken') === null){
      token = sessionStorage.getItem('CXToken');
    } else {
      token = sessionStorage.getItem('msal.idtoken');
    }

    if ((request.url.includes(environment.B2CConfigs.BackendURL)
      || request.url.includes(`${environment.cxPortal.baseUrl}`)) && token ) {
      // const contentType = request.headers.get('Content-Type') || 'application/json;charset=UTF-8';
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
        withCredentials: true,
      });

      if (request.url.includes('/api/cpq/')) {
        if (request.url.includes('/cpq/userprefs')) {
          return next.handle(request);
        } else {
          return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              return this.cpqCatchError(error);
            }) as any
          );
        }

      } else {
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            return this.catchPlatformError(error);
          }) as any
        );
      }

    } else {
      return next.handle(request);
    }
  }

  catchPlatformError(error: HttpErrorResponse): Observable<HttpErrorResponse>  {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      console.warn('There was JavaScript error');
      errorMsg = `Error: ${error.error.message}`;
    } else {
      errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
    }
    // should this error be handled or passed?
    if (this.handlePlatformErrors(error, errorMsg)) {
      throw (errorMsg);
    }
    return throwError(error);
  }

  handlePlatformErrors(event: HttpErrorResponse, errorMsg: string): boolean {
    switch (event.status) {
      // Cases that should be passed back to the controllers without intervention
      case HttpStatus.BAD_REQUEST:
      case HttpStatus.NOT_FOUND:
        return false;
      // Cases that should be announced
      case this.BROWSER_REQUEST_ERROR:
      case HttpStatus.INTERNAL_SERVER_ERROR:
        this.showInfoToaster(this.TOASTR.TEXT.MSG.INTERNAL_SERVER_ERROR);
        break;
      case HttpStatus.FORBIDDEN:
        this.showInfoToaster(this.TOASTR.TEXT.MSG.FORBIDDEN);
        break;
      // Cases that should result in logout
      case HttpStatus.UNAUTHORIZED:
        this.showErrorToaster(this.TOASTR.TEXT.MSG.UNAUTHORIZED);
        this.logoutService.logout();
        break;
      case 511:
        this.logoutService.logout();
        break;
      default:
        this.showErrorToaster(errorMsg);
        break;
    }
    return true;
  }


  cpqCatchError(error: HttpErrorResponse): Observable<HttpErrorResponse> {
    let errorMsg = '';
    if (error.error instanceof ErrorEvent) {
      console.warn('There was JavaScript error');
      errorMsg = `Error: ${error.error.message}`;
    } else {
      errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
    }

    // should this error be handled or passed?
    if (this.handleCpqErrors(error, errorMsg)) {
      throw (errorMsg);
    }

    return throwError(error);
  }

  handleCpqErrors(event: HttpErrorResponse, errorMsg: string): boolean {
    switch (event.status) {
      // Cases that should be passed back to the controllers without intervention
      case HttpStatus.BAD_REQUEST:
      case HttpStatus.NOT_FOUND:
        return false;
        break;

      // Cases that should be announced
      case this.BROWSER_REQUEST_ERROR:
      case HttpStatus.INTERNAL_SERVER_ERROR:
        // this.showInfoToaster(this.TOASTR.TEXT.MSG.INTERNAL_SERVER_ERROR); // Remove comment after update api fix
        break;
      case HttpStatus.FORBIDDEN:
        this.showInfoToaster(this.TOASTR.TEXT.MSG.FORBIDDEN);
        break;

      // Cases that should result in logout
      case HttpStatus.UNAUTHORIZED:
        this.showErrorToaster(this.TOASTR.TEXT.MSG.UNAUTHORIZED);
        break;

      default:
        this.showErrorToaster(errorMsg);
        break;
    }
    this.spinner.hide();
    return true;
  }

  showInfoToaster(msg: string) {
    this.toastr.warning(msg, this.TOASTR.TEXT.WARNING_TITLE, {
      timeOut: this.TOASTR.VALUE.WARNING_FADE_TIMEOUT,
    });
  }

  showErrorToaster(msg: string) {
    this.toastr.error(msg, this.TOASTR.TEXT.ERROR_TITLE, {
      disableTimeOut: true,
      closeButton: true,
      tapToDismiss: false,
    }).onHidden.pipe(first()).subscribe(
      x => this.logoutService.logout(), err => this.logoutService.logout()
    );
  }

}
