<!-- classname : options -->
<mat-checkbox *ngIf="optionData.selectionType=='CheckBox'" fxFlex="30%" id="{{optionData.id}}"
  matTooltip={{optionData.HoverOver}} matTooltipPosition="above" [(ngModel)]="optionData.selected"
  [disabled]="optionData.disabled == 1" [checked]="optionData.selected ==1"
  (ngModelChange)="optionData.selected=$event;checkboxClick($event)">
  {{optionData.label}}
</mat-checkbox>

<!-- classname : options -->
<mat-checkbox *ngIf="optionData.selectionType=='PredefinedCheckBox'" fxFlex="30%" id="{{optionData.id}}"
  matTooltip={{optionData.HoverOver}} matTooltipPosition="above" [disabled]="true" [checked]="true">
  {{optionData.label}}
</mat-checkbox>

<!-- selectionType: Quantity -->
<div class="same-row-controls" *ngIf="optionData.selectionType=='Quantity'">
  <label class="labelClass child-control">{{optionData.label}}</label>
  <mat-form-field class="child-control" appearance="outline" *ngIf="optionData.selectionType=='Quantity'">
    <input matInput [(ngModel)]="optionData.quantity" appDebounce [debounce]=100 [disabled]="optionData.disabled"
      step="{{optionData.stepValue}}" type="number" min="{{optionData.minValue}}" max="{{optionData.maxValue}}"
      (valueChange)="quantityUpdate()" (keypress)="isNumber($event)" matTooltipPosition="above"
      matTooltip={{optionData.HoverOver}}>
    <mat-hint>{{optionData.label}}</mat-hint>
  </mat-form-field>
</div>

<!-- selectionType: NumericInput -->
<div class="same-row-controls" *ngIf="optionData.selectionType=='NumericInput'">
  <label class="labelClass child-control">{{optionData.label}}</label>
  <mat-form-field class="child-control" appearance="outline">
    <input matInput [(ngModel)]="optionData.value" appDebounce [debounce]=100 [disabled]="optionData.disabled"
      step="{{optionData.stepValue}}" type="text" min="{{optionData.minValue}}" max="{{optionData.maxValue}}"
      (valueChange)="numericalUpdate()"  matTooltipPosition="above"
      matTooltip={{optionData.HoverOver}}>
    <mat-hint>{{optionData.label}}</mat-hint>
  </mat-form-field>
</div>

<!-- selectionType: PredefinedNumericBox-->
<mat-form-field appearance="outline" *ngIf="optionData.selectionType=='PredefinedNumericBox'">
  <input matInput [(ngModel)]=" optionData.value" [disabled]="true" type="number" matTooltipPosition="above"
    matTooltip={{optionData.HoverOver}}>
  <mat-hint>{{optionData.label}}</mat-hint>
</mat-form-field>

<!-- selectionType: PredefinedQuantityBox-->
<mat-form-field appearance="outline" *ngIf="optionData.selectionType=='PredefinedQuantity'">
  <input matInput [(ngModel)]=" optionData.quantity" [disabled]="true" type="number" matTooltipPosition="above"
    matTooltip={{optionData.HoverOver}}>
  <mat-hint>{{optionData.label}}</mat-hint>
</mat-form-field>

<!-- selectionType: StringInput -->
<div class="same-row-controls" *ngIf="optionData.selectionType=='StringInput'">
  <label class="labelClass child-control">{{optionData.label}}</label>  
  <mat-form-field appearance="outline" [style.minWidth.px]=327 *ngIf="optionData.selectionType=='StringInput'">
    <input matInput type="text" id="stringOption" [disabled]="optionData.disabled" appDebounce [debounce]=100
      [placeholder]="optionData.description" [(ngModel)]="optionData.textValue" (valueChange)="stringUpdate()">
  </mat-form-field>
</div>
