import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { StatusCodes } from 'http-status-codes';
import jwt_decode from 'jwt-decode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-cx-cpq-sso',
	template: ''
})
export class CxCpqSsoComponent implements OnInit {
	token: string;
	decodedToken: any;	// FIXME create the object definition for our token
	user: any;	// FIXME create the object definition for our user

	constructor(private route: ActivatedRoute,
		private router: Router,
		private userService: UsersService,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService,
		private logoutService: LogoutService
	) {
		this.route.queryParams.subscribe(params => {
			this.token = params.token;
			this.decodedToken = jwt_decode(params.token);
		});
	}

	ngOnInit(): void {
		sessionStorage.setItem('CXToken', this.token);
		sessionStorage.setItem('CXDecodedToken', this.decodedToken);	// FIXME storage only allows strings; serialization must be explict
		this.checkUserAvailableInCPQ();
	}

	checkUserAvailableInCPQ() {
		this.spinner.show();
		this.userService.getUser(this.decodedToken.oid).subscribe((res) => {
			this.spinner.hide();
			if (res['statusCode'] === StatusCodes.OK && res['data'] !== null) {
				this.user = res['data'];
				this.router.navigate(['']);
			} else {
				this.toastr.error('User is not registered within application');
				this.logoutService.logout();
			}
		},
			(err) => {
				this.spinner.hide();
				this.toastr.error(err.errorMessage);
				this.logoutService.logout();
			})
	}
}
