<div class="clearfix visualize-container">
  <div *ngIf="enable3d" class="3dViewerTab" id="3dViewerTab" #3dViewerTab>
    <div class="expand-icon-container" (click)="onExpandButtonClick()">
      <i class="fa window-control-icon" [ngClass]="{'fa-expand fa-expand-alt': !isExpandViewOn,
        'fa-compress fa-compress-alt': isExpandViewOn}" aria-hidden="true"></i>
    </div>
    <!-- <div class="row m-0 icon-container" fxLayoutAlign="space-around center">
      <img class="group2" onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_ISOMETRIC)"
        src="assets/images/icons/camera_perspective.png">
      <img onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_TOP)" src="assets/images/icons/camera_top.png">
      <img onclick="cds.cadViewer.setOrientation(cds.cadViewer.VIEW_LEFT)" src="assets/images/icons/camera_left.png">
    </div> -->
    <div id="cdsRenderer" class="cds-cad-viewer-container row m-0 justify-content-center">
    </div>
    <button id="resetView" class="cds-button" (click)="resetView()">Reset View</button>
    <button id="driveThrough" class="cds-button" (click)="driveThroughTunnel()">Drive Through Tunnel</button>
    <button id="augmentedReality" class="cds-button" (click)='showQRCode()' *ngIf="arEnabled">
      View in Augmented Reality
        <i *ngIf="isQRCodeLoading" class="fa fa-spinner fa-pulse fa-fw"></i>
    </button>
    <button id="close QR" class="cds-button" (click)='hideQRCode()' *ngIf="arEnabled">Close QR</button>
    <button id="share3d" class="cds-button" (click)="share3dLink()" [disabled]="!sharable">Share 3D</button>

  </div>
  <div *ngIf="!enable3d" class="product-image">
    <img [src]="configData?.summary?.graphicName" alt="{{configData?.summary?.name}}">
  </div>
</div>