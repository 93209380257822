import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() optionData = [];
  @Input() groupName: string;
  @Output() valueChange = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onSelectionChange(item) {
    item.value = 1;
    this.valueChange.emit(item);
  }
}
