import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService, CpqObjects, JOB_STATUS } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { CursorCssClass, DatatableColumn, ExpiryCssClass, RowActions as TableRowActions, StatusCssClass, PageHeaderInformation, Icons, ButtonClass, PageName, ButtonTitle } from '@cpq-app/shared/datatable/datatable';
import { TruncatePipe } from '@cpq-app/shared/pipes/truncate.pipe';
import { CPQ_EXPORT_STATUS, CpqJob } from '@cpq-app/tenants/Cpq.interfaces.service';
import { ShareComponent } from '@cpq-app/tenants/VWS/share/share.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { differenceInCalendarDays, isBefore } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CURRENT_RECORD_STATE } from '@vws-app/configuration/configuration.component';
import { CpqOperations } from '@vws-app/cpqOperations.service';
import { SessionVariables } from '../header-section/header-section.component';
import { environment } from '@cpq-environments/environment';
import { SalesforceProxyService, SfdcLeadSource, VwsSfdcOpportunityStage } from '@cpq-app/services/salesforce.service';

const DATE_FMT = 'yyyy-MM-dd';
const POST_EXPIRED = 'The pricing on your quote has expired and will now be updated.';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  providers: [CurrencyPipe, TruncatePipe]
})

export class JobsComponent implements OnInit, OnDestroy {
  jobs: CpqJob[] = [];
  isDistributor: boolean;
  columnDefinitions$: Subject<DatatableColumn<CpqJob>[]>;
  newJobID: string;

  jobID: string;
  subscription$: Subscription[] = [];
  jobSubscription: Subscription;
  customerView = false;
  dataLoading = false;
  pageHeaderInformation: PageHeaderInformation = {
    title: PageName.job,
    buttons: [{
      field: 'job',
      title: ButtonTitle.job,
      icon: Icons.plus,
      className: ButtonClass.dark,
    }]
  }

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private cartService: CartService,
    private loginService: LoginService,
    private userService: UsersService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private vwsCpqOperations: CpqOperations,
    private salesforce: SalesforceProxyService
  ) {
    this.cartService.selectedHeaderInfo$.next('');
  }

  ngOnInit(): void {
    this.spinner.show();
    this.subscribeToCustomerView();
    this.subscription$.push(this.userService.getUserLoadedSub().subscribe(res=>{
      if(res){
        this.isDistributor = this.userService.checkLoggedInUserIsDistributor();
        const colDef = this.columnDefinitionFactory();
        this.columnDefinitions$ = new BehaviorSubject<DatatableColumn<CpqJob>[]>(colDef);  
        setTimeout(() => {
          this.fetchJobs();
        }, 125);    
      }
    }));


    // session API call on application load
    // const loggedIn$ = this.loginService.loginUser();
    // this.subscription$.push(loggedIn$.subscribe({
    //   next: () => {
    //     this.disableAddJobButton = false;
    //     this.isDistributor = this.userService.checkLoggedInUserIsDistributor();
    //     this.fetchJobs();
    //   }
    // }));

    this.subscription$.push(this.cartService.accountsUpdated$.subscribe({
      next: () => {
        this.fetchJobs();
      }
    }));
   

  }

  navigateToBeforeLoginUrl() {
    let beforeLoginUrl = sessionStorage.getItem('msal.state.login').split('|')[1];
    let navigationStatus = sessionStorage.getItem(SessionVariables.NAVIGATED_TO_BEFORELOGIN_URL);
    if (navigationStatus === null) {
      let locationURL = window.location.href;
      sessionStorage.setItem(SessionVariables.NAVIGATED_TO_BEFORELOGIN_URL, 'true');
      locationURL = `${environment.B2CConfigs.redirectUrl}${beforeLoginUrl}`;
    }
  }

  columnDefinitionFactory(): DatatableColumn<CpqJob>[] {
    const colData: DatatableColumn<CpqJob>[] = [
      {
        field: 'Name', header: 'Job Name', isPrimary: true,
        visibility: true, sortable: true, filterable: true,
        value: (job: CpqJob) => job?.Name, handler: (record: CpqJob) => this.onClickEdit(record),
        className: () => CursorCssClass.ALLOW_CLICK,
        compare: (a: CpqJob, b: CpqJob) => a?.Name?.localeCompare(b?.Name),
      },
      {
        field: 'IsClosed', header: 'Status',
        visibility: true, sortable: true, filterable: false,
        value: (job: CpqJob) => this.cartService.getVWSOpportunityStatus(job),
        className: (job: CpqJob) => this.returnStatusClass(job),
        compare: (a: CpqJob, b: CpqJob) => {
          const aS = this.cartService.getVWSOpportunityStatus(a) ?? JOB_STATUS.INACTIVE;
          const bS = this.cartService.getVWSOpportunityStatus(b) ?? JOB_STATUS.INACTIVE;
          return aS.localeCompare(bS);
        }
      },
      {
        field: 'TotalAmount', header: 'Total Amount',
        visibility: !this.customerView, sortable: true,
        value: (job: CpqJob) => this.currencyPipe.transform(job?.PrimaryQuote && job?.PrimaryQuote?.TotalAmount !== null ? job?.PrimaryQuote?.TotalAmount : 0),
        compare: (a: CpqJob, b: CpqJob) => {
          const totalAmount_1 = a?.PrimaryQuote ? Number(a.PrimaryQuote?.TotalAmount) : 0;
          const totalAmount_2 = b?.PrimaryQuote ? Number(b.PrimaryQuote?.TotalAmount) : 0;
          return totalAmount_1 - totalAmount_2;
        },
      },
      {
        field: 'Account_Name', header: 'Account Name',
        visibility: true, sortable: true, filterable: true,
        value: (job: CpqJob) => job?.Account?.Name,
        compare: (a: CpqJob, b: CpqJob) => {
          const account_1 = a?.Account ? a.Account?.Name : '';
          const account_2 = b?.Account ? b.Account?.Name : '';
          return account_1.localeCompare(account_2)
        },
      },
      {
        field: 'Owner_Name', header: 'Owner',
        visibility: true, sortable: true, filterable: true,
        value: (job: CpqJob) => job.Owner?.Name,
        compare: (a: CpqJob, b: CpqJob) => {
          const owner_1 = a.Owner.Name !== null ? a.Owner.Name : '';
          const owner_2 = b.Owner.Name !== null ? b.Owner.Name : '';
          return owner_1.localeCompare(owner_2)
        }
      },
      {
        field: 'ExpiresIn', header: 'Expires in days',
        visibility: true, sortable: true, filterable: true,
        showColorIndicator: true,
        value: (record: CpqJob) => this.returnExpire(record),
        className: (record: CpqJob) => this.returnExpireClass(record),
        titleText: (record: CpqJob) => this.returnTitleText(record),
        compare: (a: CpqJob, b: CpqJob) => {
          const aE = this.getDifferenceInCalendarDays(a);
          const bE = this.getDifferenceInCalendarDays(b);
          return Number(aE > bE) - Number(aE < bE);
        }
      },
      {
        field: 'ModifiedTime', header: 'Last Modified',
        initialSort: true, sortable: true, visibility: true,
        value: (job: CpqJob) => this.datePipe.transform(job.ModifiedTime, DATE_FMT),
        compare: (a: CpqJob, b: CpqJob) => {
          const aD = new Date(a.ModifiedTime);
          const bD = new Date(b.ModifiedTime);
          return Number(aD > bD) - Number(aD < bD);
        }
      },
      {
        field: 'actions', header: 'Actions', visibility: true, 
        disableActionProperty: TableRowActions.DELETE,
        disable: (record: CpqJob) => (record.CrmExportStatus === CPQ_EXPORT_STATUS.SUBMITTED),
        value: (record) => {
          const actionsArray = [{ action: TableRowActions.EDIT, handler: () => this.onClickEdit(record) },
          { action: TableRowActions.COPY, handler: () => this.onClickCopy(record) }];

          if (!this.isDistributor) { // Adding Delete action if user is not a distributor
            const deleteAction = { action: TableRowActions.DELETE, handler: () => this.openDeleteModel(record) };
            actionsArray.push(deleteAction);
          }
          // if (this.isDistributor) {
          //   const shareAction = { action: TableRowActions.SHARE, handler: () => this.onClickShare(record) };
          //   actionsArray.push(shareAction);
          // }
          return actionsArray;
        }
      }
    ];
    return colData;
  }

  returnStatusClass(job: CpqJob): string {
    let opportunityStatus;
    if (job.PrimaryQuoteId == null && job.CrmExportStatus !== CPQ_EXPORT_STATUS.SUBMITTED) {
      opportunityStatus = StatusCssClass.INACTIVE;

    } else if (job.PrimaryQuoteId != null && job.CrmExportStatus === CPQ_EXPORT_STATUS.SUBMITTED) {
      opportunityStatus = StatusCssClass.CLOSE;

    } else if (job.PrimaryQuoteId != null && job.CrmExportStatus !== CPQ_EXPORT_STATUS.SUBMITTED) {
      const primaryQuote = job.PrimaryQuote
      const currentDate = new Date();
      if (primaryQuote && new Date(primaryQuote.ExpirationTime) > currentDate) {
        opportunityStatus = StatusCssClass.ACTIVE;
      } else {
        opportunityStatus = StatusCssClass.INACTIVE;
      }
    }
    return opportunityStatus;
  }

  returnExpireClass(record: CpqJob): string {
    const diff = this.returnExpire(record);
    try {
      if (diff === '') {
        return ExpiryCssClass.EXPIRED;
      } else if (diff === '99+') {
        return ExpiryCssClass.NORMAL_TRIPLE_DIGIT;
      } else if (typeof (diff) === 'number') {
        if (diff > 0 && diff <= 2) {
          return ExpiryCssClass.DANGER;
        } else if (diff > 2 && diff <= 4) {
          return ExpiryCssClass.WARNING;
        } else if (diff > 4 && diff <= 7) {
          return ExpiryCssClass.MODERATE;
        } else if (diff > 7 && diff <= 9) {
          return ExpiryCssClass.NORMAL_SINGLE_DIGIT;
        }
        else {
          return ExpiryCssClass.NORMAL;
        }
      }
      else {
        return ExpiryCssClass.NORMAL;
      }
    } catch (err) {
      console.log(err);
    }
  }

  returnTitleText(record: CpqJob): string {
    const differences = this.getDifferenceInCalendarDays(record);
    let value = `The primary configuration will expire in ${differences} days`;
    if (differences <= 0) {
      value = `The primary configuration is expired`;
    }
    return value;
  }

  getDifferenceInCalendarDays(record: CpqJob) {
    return record.PrimaryQuote?.ExpirationTime ? differenceInCalendarDays(
      new Date(record.PrimaryQuote?.ExpirationTime), new Date()) : -1
  }

  // This function is for display value
  returnExpire(record: CpqJob): string | number {
    const difference = this.getDifferenceInCalendarDays(record);
    let value: number | string = difference;
    if (difference > 99) {
      value = '99+';
    } else if (difference <= 0) {
      value = ''; // Passing empty value to apply No Entry class
    }
    return value;
  }

  onClickEdit(job: CpqJob) {
    try {
      this.router.navigate(['/job', job.Id]);
    } catch (error) {
      console.log(error);
    }
  }

  fetchJobs() {
    if (this.jobSubscription) {
      this.jobSubscription.unsubscribe();
    }

    this.dataLoading = true;
    this.jobs = [];

    this.jobSubscription = this.cartService.getCpqObjectsCDS<any>(
      CpqObjects.Opportunities)
      .subscribe({
        next: (data: CpqJob[]) => {
          const jobList = [...this.jobs, ...data];
          this.jobs = jobList.sort((a, b) => {
            const aD = new Date(a.ModifiedTime);
            const bD = new Date(b.ModifiedTime);
            return Number(aD < bD) - Number(aD > bD);
          });
          this.spinner.hide();
        },
        complete: () => {
          this.dataLoading = false;
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while fetching jobs', 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        }
      });
  }


  checkPrimaryQuoteExpiry(copiedJob: CpqJob) {
    if (Boolean(copiedJob) && copiedJob?.PrimaryQuote) {
      const expiryDate = copiedJob.PrimaryQuote.ExpirationTime;
      const dateBefore = isBefore(new Date(expiryDate), new Date());
      if (dateBefore) {
        this.cartService.getCpqObjectByIdCDS(CpqObjects.Opportunities, copiedJob.Id).subscribe({
          next: (opp: any) => {
            this.spinner.hide();
            if (opp.PrimaryQuote?.Id) {
              this.openWarningModel(opp.PrimaryQuote.Id, true, POST_EXPIRED);
            }
          },
          error: err => { console.log(err); this.spinner.hide(); }
        });
      } else {
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }
  }

  /**
   * @param quoteID - pass quote ID to update the products information
   * @param isRecordExpired - is using to navigate to choose page or not
   * @param message - What message to display on popup
   */
  openWarningModel(quoteID: string, isRecordExpired: boolean, message: string) {
    const data = {
      message,
      recordID: quoteID,
      isRecordExpired
    };
    const instance = this.vwsCpqOperations.openModelDialog(data);
    instance.result.then((outcome) => {
      if (outcome === quoteID) {
        this.fetchJobs();
      }
    });
  }

  onClickCopy(record: CpqJob) {
    this.spinner.show();
    const newName = `Copy of ${record?.Name}`;
    let newRecord: CpqJob = Object.assign({}, record);
    const payload = {
      Name: newName,
      CrmExportStatus: CPQ_EXPORT_STATUS.NOT_EXPORTED,
      CrmId: null
    }
    newRecord = {
      ...newRecord, CrmExportStatus: CPQ_EXPORT_STATUS.NOT_EXPORTED, CrmId:null }
    this.cartService.copyObjectById(CpqObjects.Opportunity, record?.Id, payload).pipe(
      switchMap((results: CpqJob) => {
        newRecord.Id = results.Id;
        newRecord.Name = newName;
        this.checkPrimaryQuoteExpiry(newRecord);
        this.jobs = [newRecord, ...this.jobs];
        return this.createSFDCOpp(newRecord);
      })
    ).subscribe({
      next: () => {
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.toastr.error(
          'There is fatal error while copying jobs', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  /**
   * Create opportunity when new quote-line is created
   */
  createSFDCOpp(opportunity) {
    const isDistributor = opportunity?.Owner?.PartnerId ? true : false;
    let payload: any = {
      Name: opportunity?.Name,
      StageName: VwsSfdcOpportunityStage.DEFAULT,
      CloseDate: opportunity?.ShippingDate,
      LeadSource: SfdcLeadSource.CPQ,
      Projected_Ship_Date__c: opportunity?.ShippingDate,
      CPQ_Opportunity_Owner_Email__c: opportunity.Owner?.Email,
      Outside__c: isDistributor,
      CPQ_Job_Link__c: environment.B2CConfigs.redirectUrl + '/job/' + opportunity.Id
    }
    return this.salesforce.createOpportunity(payload).pipe(
      switchMap(res => {
        opportunity.CrmId = res.id;
        return this.cartService.updateObjectById(CpqObjects.Opportunity, opportunity.Id, {
          CrmId: res.id,
          Name: opportunity?.Name
        });
      }));
  }

  /**
   * @param id - pass quote ID to update the products information
   */
  openDeleteModel(record: CpqJob) {
    const message = 'Do you really want to delete the job?';
    const data = {
      message,
      recordID: record.Id,
    };
    const instance = this.vwsCpqOperations.openDeleteModelDialog(data);
    instance.result.then((outcome) => {
      if (outcome === record.Id) {
        this.onClickDelete(record)
      }
    })
  }

  onClickDelete(record: CpqJob) {
    this.spinner.show();
    this.subscription$.push(this.cartService.deleteObjectByObjectId(CpqObjects.Opportunities, record.Id).subscribe({
      next: () => {
        this.jobs = this.jobs.filter(item => (item.Id.indexOf(record.Id) === -1));
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.toastr.error(
          'There is fatal error while deleting jobs', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    }));
  }



  onClickShare(record: CpqJob) {
    const instance = this.modalService.open(ShareComponent, {
      size: 'md',
      centered: true
    }).componentInstance;
    instance.jobID = record.Id;
    this.activeModal.close();
  }

  subscribeToCustomerView() {
    this.subscription$.push(this.userService.getCustomerView()
      .subscribe({
        next: isActive => {
          this.customerView = isActive;
          this.columnDefinitions$?.next(this.columnDefinitionFactory());
        },
        complete: () => this.subscribeToCustomerView(),
      }));
  }


  createJobAndRevisionWithDefaultValues() {
    this.spinner.show();
    try {
      this.subscription$.push(this.vwsCpqOperations.createVWSJob().subscribe({
        next: (opportunityID: any) => {
          this.jobID = opportunityID;
          this.spinner.hide();
          sessionStorage.setItem(CURRENT_RECORD_STATE.RECORD_STATE, CURRENT_RECORD_STATE.CREATE_VIEW);
          // navigate to tunnel option page
          setTimeout(() => { this.router.navigate([this.jobID, 'choose', 'results']); },50)
        
        },
        error: err => {
          this.spinner.hide();
          this.showToasterMsg();
        }
      }));
    } catch (err) {
      this.spinner.hide();
      this.showToasterMsg();
    }
  }

  showToasterMsg() {
    this.toastr.error(
      'There is fatal error while creating Job', 'Error', {
      disableTimeOut: true,
      closeButton: true
    }
    );
  }

  ngOnDestroy() {
    this.jobSubscription?.unsubscribe();
    this.subscription$?.forEach(sub => sub?.unsubscribe());
  }

  buttonClick(event) {
    this.createJobAndRevisionWithDefaultValues();
  }
}
