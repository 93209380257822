<form class="p-3" [formGroup]="createJobForm">
    <div class="account-parent-block">
        <div class="account-block" *ngIf="!isNotQuoteEditPage()">
            <div class="account-select form-group">
                <label for="AccountId" class="control-label">Account Name</label>
                <select class="form-control account-select" formControlName="AccountId" id="AccountId">
                    <option *ngIf="!editAccountForm" value="null" disabled>Select Account Name</option>
                    <option *ngIf="editAccountForm" [ngValue]="undefined" disabled>Select Account Name</option>
                    <option *ngFor="let item of accountNames" [ngValue]="item.Id">{{item?.Name}}</option>
                </select>
            </div>
            <div class="or-block">
                <span class="or">OR</span>
            </div>
            <div class="form-group">
                <label for="AccountId" class="control-label"></label>
                <button class="add-account" (click)="redirectToAccountView()">Add Account</button>
            </div>
        </div>
        <hr class="hr-block" *ngIf="!isNotQuoteEditPage()">
    </div>
    <div class="job-detail-fields" [ngClass]="{'applyMargin':!isNotQuoteEditPage() }">
        <div class="form-group required">
            <label for="Name" class="control-label">Job Name</label>
            <input validateOnBlur type="text" class="form-control" id="job" formControlName="Name" placeholder="Name" [readOnly]="isOrderPlaced"
                [ngClass]="{ 'is-invalid':  (createJobForm.controls['Name'].dirty || createJobForm.controls['Name'].touched || submitted) && createJobForm.controls['Name'].errors }">
            <div *ngIf="createJobForm.controls['Name'].invalid && (createJobForm.controls['Name'].dirty || createJobForm.controls['Name'].touched || submitted)"
                class="invalid-feedback">
                <div *ngIf="createJobForm.controls['Name'].errors.required">
                    Job Name is required</div>
                <div *ngIf="createJobForm.controls['Name'].errors.pattern">
                    Job Name must be a character</div>
                <div *ngIf="createJobForm.controls['Name'].errors.maxlength">Job Name must contain max of 120
                    characters</div>
            </div>
        </div>
        <div class="same-row-controls">
            <div class="form-group required first-control">
                <label for="CurrencyIsoCode" class="control-label">Currency</label>
                <select class="form-control" id="currency" formControlName="CurrencyIsoCode"
                    [ngClass]="{ 'is-invalid':  (createJobForm.controls['CurrencyIsoCode'].dirty || createJobForm.controls['CurrencyIsoCode'].touched || submitted) && createJobForm.controls['CurrencyIsoCode'].errors }">
                    <option [ngValue]="null" disabled>Select Currency</option>
                    <option *ngFor="let currency of currencyData" [ngValue]="currency">{{currency}}</option>
                </select>
                <div *ngIf="createJobForm.controls['CurrencyIsoCode'].invalid && (createJobForm.controls['CurrencyIsoCode'].dirty || createJobForm.controls['CurrencyIsoCode'].touched || submitted)"
                    class="invalid-feedback">
                    <div *ngIf="createJobForm.controls['CurrencyIsoCode'].errors.required">Currency is required
                    </div>
                </div>
            </div>
            <div class="form-group second-control" *ngIf="isNotQuoteEditPage()">
                <label for="opportunityOwner" class="control-label">Opportunity Owner</label>
                <input type="text" class="form-control" id="OpportunityOwner" formControlName="OpportunityOwner"
                    placeholder="">
            </div>
        </div>
        <div class="same-row-controls">
            <div class="form-group first-control" *ngIf="isNotQuoteEditPage()">
                <label for="AccountId" class="control-label">Account Name</label>
                <select class="form-control account-select" formControlName="AccountId" id="AccountId" [disabled]="isOrderPlaced">
                    <option *ngIf="!editAccountForm" value="null" disabled>Select Account Name</option>
                    <option *ngIf="editAccountForm" [ngValue]="undefined" disabled>Select Account Name</option>
                    <option *ngFor="let item of accountNames" [ngValue]="item.Id">{{item?.Name}}</option>
                </select>
            </div>

            <div class="form-group required second-control">
                <label for="ShippingDate" class="control-label">Expected Ship Date</label>
                <div class="input-group input-icons">
                    <input (click)="picker.toggle()" class="form-control" formControlName="ShippingDate"
                        placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #picker="ngbDatepicker" [disabled]="isOrderPlaced"
                        [minDate]="minPredictedDate"
                        [ngClass]="{ 'is-invalid':  (createJobForm.controls['ShippingDate'].dirty|| submitted) && createJobForm.controls['ShippingDate'].errors }">
                    <i class="fa fa-calendar calendar m-1" (click)="picker.toggle()"></i>
                     
                    <div *ngIf="createJobForm.controls['ShippingDate'].invalid && (createJobForm.controls['ShippingDate'].dirty || submitted)"
                                            class="invalid-feedback">
                        <div *ngIf="!createJobForm.controls['ShippingDate'].errors.required">{{validationMessage}}
                        </div>
                        <div *ngIf="createJobForm.controls['ShippingDate'].errors.required">Expected Ship Date is
                            required
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *hideFromCustomer>
            <div class="note-details" *ngIf="isNotQuoteEditPage()">
                <div class="form-group">
                    <label for="Note__c" class="control-label">Note</label>
                    <textarea class="form-control notes" id="note" rows="3" placeholder="Notes"
                        formControlName="Note__c" [readOnly]="isOrderPlaced"
                        [ngClass]="{ 'is-invalid': submitted && createJobForm.controls['Note__c'].errors }"></textarea>
                    <div *ngIf="submitted && createJobForm.controls['Note__c'].errors" class="invalid-feedback">
                        <div *ngIf="createJobForm.controls['Note__c'].errors.maxlength">Note must contain max of
                            2000 characters
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end flex-wrap" *ngIf="isNotQuoteEditPage()">
            <button type="submit" class="save-button mr-2" [disabled]='!isFormValid() || isOrderPlaced' (click)="onSave()">Save</button>
            <button type="button" class="cancel-button" (click)="redirectToLandingPage()">Cancel</button>
        </div>
    </div>
</form>