import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Grouping, Option, Node, ProductService } from '@cpq-app/services/product.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Tunnel } from '../VWS.interfaces.service';

const ALL_CATEGORIES = 'All';
const NO_IMAGE_PLACEHOLDER = 'No_image_available.png';
const PATH_TO_IMAGES = '/assets/images/vws/products/';
const INPUT_DEBOUNCE_TIME_MS = 300;
const HTML_IMG_PATTERN = /cpq\/images\/([\w\d-._]+)\"/i;

export enum ModalEvents {
  OnProductClick = 'OnProductClick',
}
@Component({
  selector: 'app-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.scss']
})
export class AddProductsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() mainGroup;
  @Input() categoryName: string;
  @Input() productType: string
  @Output() selectedProductData = new EventEmitter();
  ADD_PRODUCT_SPINNER = 'addProduct';

  filteredCategoriesWithProducts;
  availableCategories: string[];
  productsInSelectedCategory: Grouping[];
  categoryDetails: UntypedFormGroup;
  createNodeSubscription: Subscription;
  searchStringSubject = new BehaviorSubject<string>('');
  inputSubscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private productService: ProductService,) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.mainGroup) {
      this.mainGroup = changes?.mainGroup.currentValue;
      this.getInitialData();
    }
  }

  ngOnInit(): void {
    this.inputSubscription = combineLatest([this.searchStringSubject])
      .pipe(
        tap(x => console.log(`Triggered`, x)),
        debounceTime(INPUT_DEBOUNCE_TIME_MS)
      ).subscribe({
        next: ([search]) => {
          this.FilterData();
          this.applyFilters(search);
        },
        error: err => {
          console.log(err);
        }
      });
  }

  ngOnDestroy(): void {
    this.inputSubscription.unsubscribe();
  }

  getInitialData() {
    try {
      this.FilterData();
      this.filteredCategoriesWithProducts = [{ ...this.mainGroup }];
      this.categoryDetails = this.fb.group({
        searchString: ['']
      });

    } catch (error) {
      console.log(error);
    }
  }

  onSearchChange(): void {
    let search = this.categoryDetails.value.searchString;
    search = search?.trim().toLowerCase();

    this.searchStringSubject.next(search);
  }

  /**
   * Filters the collection of parts.
   * Keeps only the categories with a name that matches the passed name. If undefined or set to all, then all categories are returned.
   * Within each filtered category, only the nodes / parts that have the search string in their label are kept. If undefined
   * or empty string is passed, all nodes are returned.
   * @param searchString the text to filter the labels
   */
  applyFilters(searchString: string): void {
    try {
      /*
       * Note: The structure is a deep object, and all operations must be careful not modify the incoming mainGroup data,
       * otherwise those values will be lost and not restorable when the filter is removed. Hence the use of a spread operator
       * and assign to create a new object.
       */
      this.filteredCategoriesWithProducts = [{ ...this.mainGroup }];
      if (searchString) {
        this.filteredCategoriesWithProducts = this.filteredCategoriesWithProducts.reduce((filtered, category) => {
          const newCategory = {
            classname: '',
            CategoryId: '',
            minimum: 0,
            maximum: 0,
            Products: []
          };
          Object.assign(newCategory, category);
          newCategory.Products = category.Products?.filter(node => node.Label?.toLowerCase().includes(searchString));
          if (newCategory.Products.length > 0) {
            filtered.push(newCategory);
          }
          return filtered;
        }, []);
      }
    } catch (error) {
      console.log(error);
    }
  }

  add(cardData: Node) {
    this.selectedProductData.emit(cardData);
  }

  getImageUrl(cardData: Option): string {
    return this.productService.getImageUrl(cardData?.ImageUrl);
  }

  /**
   * Filter products based on product type
   */
  FilterData() {
    switch (this.productType) {
      case Tunnel.avProductLegend:
        this.mainGroup.Products = this.returnLegendProduct()
        break;
      case Tunnel.avProductSpinLite:
        this.mainGroup.Products = this.returnSpinLiteProduct()
        break;
      case Tunnel.avProductSpinRite:
        this.mainGroup.Products = this.returnSpinRiteProduct()
        break;
      default:
        break;
    }
  }

  /**
   * Returns legend type of products
   */
  returnLegendProduct() {
    return this.mainGroup.Products.filter(proudct =>
      proudct.AttributeMap?.legend_tunnel && proudct.AttributeMap.legend_tunnel === 'Yes'
    )
  }

  /**
   * Returns spin lite type of products
   */
  returnSpinLiteProduct() {
    return this.mainGroup?.Products.filter(proudct =>
      proudct.AttributeMap?.spinlite_tunnel && proudct.AttributeMap.spinlite_tunnel === 'Yes'
    )
  }

  /**
   * Returns spin rite type of products
   */
  returnSpinRiteProduct() {
    return this.mainGroup?.Products.filter(proudct =>
      proudct.AttributeMap?.spinrite_tunnel && proudct.AttributeMap.spinrite_tunnel === 'Yes'
    )
  }
}
