import { Injectable } from '@angular/core';
import { CartService, CpqObjects, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { Observable, throwError } from 'rxjs';
import { format, add } from 'date-fns';
import { VwsCpqOpportunity, VwsCpqQuote } from './VWS.interfaces.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RenewQuoteComponent } from './revision/renew-quote/renew-quote.component';
import {  CPQ_EXPORT_STATUS, Quote } from '../Cpq.interfaces.service';
import { DeleteComponent } from './delete/delete.component';


const DATE_FMT = 'yyyy-MM-dd';
const DATE_STRING_FMT = 'yyMMddHHmmssSSS';

const enum CPQ_OPERATIONS_SERVICE_CONSTANTS {
    USER_NAME = 'username',
    CURRENCY_USD = 'USD',
    JOB = 'Job',
    QUOTE = 'Quote'
}

const QUOTE_CONSTANTS = {
    DRIVE_SIDE: 'Left',
    SITE_VOLTAGE: '460',
    TAX_AMOUNT: '0',
    TAX_REQUIRED: false
};

const DEFAULT_MODAL_OPTIONS: NgbModalOptions = {
    size: 'md',
    backdrop: 'static'
};

interface VwsCpqNewQuote extends Pick<VwsCpqQuote,
    'Name' | 'ExpirationDate' | 'SiteDriveSide__c' | 'SiteVoltage__c' | 'TaxAmount__c' | 'TaxRequired__c'> {
    OpportunityId: string;
}

interface VwsCpqNewOpportunity extends Pick<VwsCpqOpportunity, 'CurrencyIsoCode' | 'CrmExportStatus' | 'SiteDriveSide__c' | 'Name'> { }

@Injectable({
    providedIn: 'root'
})

export class CpqOperations {

    constructor(
        private cartService: CartService,
        private modalService: NgbModal,
    ) { }


    getUserName(): string {
        const [userName] = sessionStorage.getItem(CPQ_OPERATIONS_SERVICE_CONSTANTS.USER_NAME).split('@');
        return userName;
    }

    getDateString(dateFormat: string, date = new Date()): string {
        return format(date, dateFormat);
    }

    addDaysToDate(numberOfDays: number, date = new Date()): Date {
        if (isNaN(numberOfDays)) {
            return;
        }
        return add(date, { days: numberOfDays });
    }

    getCpqObjectName(ObjectType: string): string {
        const todayDateString = this.getDateString(DATE_STRING_FMT);
        return `${this.getUserName()} ${ObjectType} ${todayDateString}`;
    }

    getNewJobObject(): VwsCpqNewOpportunity {
        const tomorrowDateString = this.getDateString(DATE_FMT, this.addDaysToDate(1));
        return {
            CurrencyIsoCode: CPQ_OPERATIONS_SERVICE_CONSTANTS.CURRENCY_USD,
            CrmExportStatus: CPQ_EXPORT_STATUS.NOT_EXPORTED,
            SiteDriveSide__c: QUOTE_CONSTANTS.DRIVE_SIDE,
            Name: 'New Job'
        };
    }

    createVWSJob(): Observable<any> {
        const jobObject = this.getNewJobObject();
        return new Observable<string>(observer => {
            const subscription = this.cartService.createObject(CpqObjects.Opportunity, jobObject).subscribe({
                next: (data: any) => {
                    observer.next(data?.Id);
                },
                error: err => {
                    observer.error(err);
                }
            });
            return {
                unsubscribe: () => subscription.unsubscribe()
            };
        });
    }

    getNewQuoteObject(opportunityId: string): any {
        const quoteExpirationDate = this.getDateString(DATE_FMT, this.addDaysToDate(30));
        return {
            ExpirationTime: quoteExpirationDate,
            Name: 'New Quote',//this.getCpqObjectName(CPQ_OPERATIONS_SERVICE_CONSTANTS.QUOTE),
            OpportunityId: opportunityId,
            // SiteDriveSide__c: QUOTE_CONSTANTS.DRIVE_SIDE,
            // SiteVoltage__c: QUOTE_CONSTANTS.SITE_VOLTAGE,
            // TaxAmount__c: QUOTE_CONSTANTS.TAX_AMOUNT,
            // TaxRequired__c: QUOTE_CONSTANTS.TAX_REQUIRED
        };
    }

    createVWSQuote(opportunityId: string): Observable<Quote> {
        if (!Boolean(opportunityId)) {
            return throwError('Opportunity ID required to create Quote');
        }
        const quoteObject = this.getNewQuoteObject(opportunityId);
        return new Observable<Quote>(observer => {
            const subscription = this.cartService.createObject(CpqObjects.Quote, quoteObject).subscribe({
                next: (data: Quote) => {
                    observer.next(data);
                },
                error: err => {
                    observer.error(err);
                }
            });
            return {
                unsubscribe: () => subscription.unsubscribe()
            };
        });
    }

    getQuoteLineProducts(quoteId: string): Observable<any> {
        return this.cartService.getCpqObjectByIdCDS<VwsCpqQuote>(
            CpqObjects.QuoteLine,
            quoteId
            // {
            //     addonFields: VwsCpqQuote.addOnFields.join(', '),
            //     resolveNames: 'true'  // FIXME: ideally, we do not send string booleans
            // }
        );
    }

    /**
     *
     * @param data - What data parent component has to pass to child component
     * @param modalOption - How popup show display. If noting pass, Model will take default values
     * @returns Modal instance to handle modal close functionality
     */
    openModelDialog(data: any, modalOption = DEFAULT_MODAL_OPTIONS): NgbModalRef {
        const instance = this.modalService.open(RenewQuoteComponent, modalOption);
        instance.componentInstance.data = data;
        return instance;
    }

/**
 *
 * @param data - What data parent component has to pass to child component
 * @param modalOption - How popup show display. If noting pass, Model will take default values
 * @returns Modal instance to handle modal close functionality
 */
    openDeleteModelDialog(data: any, modalOption = DEFAULT_MODAL_OPTIONS): NgbModalRef {
        const instance = this.modalService.open(DeleteComponent, modalOption);
        instance.componentInstance.data = data;
        return instance;
    }

    getCPQOpportunityById(opportunityID: string): Observable<VwsCpqOpportunity[]> {
        return this.cartService.getCpqObjects<VwsCpqOpportunity>(
            CpqQueryObjects.Opportunities,
            {
                Id: opportunityID,
                addonFields: VwsCpqOpportunity.addOnFields.join(', '),
                resolveNames: 'true'  // FIXME: ideally, we do not send string booleans
            });
    }

}

