import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { CartService, CpqObjects, CpqObjectType } from '@cpq-app/services/cart.service';
import { Grouping, ProductService, SaveDataRCresponse } from '@cpq-app/services/product.service';
import { SalesforceProxyService, SfdcLeadSource, VwsSfdcOpportunityStage } from '@cpq-app/services/salesforce.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Categories, ConveyorizedTunnelOptions, Products, Tunnel, VwsQuoteline } from '../VWS.interfaces.service';
import { environment } from '@cpq-environments/environment';
import { CPQ_EXPORT_STATUS } from '@cpq-app/tenants/Cpq.interfaces.service';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Share3dComponent } from '../share3d/share3d.component';
declare const cds: any;

const SCROLL_TABS_UNIT = 60;
const SCROLL_TO_BASE = 1000;
const FIRST_TAB = 0;
const SCROLL_INCREMENT = 15;

enum DEFAULT_VALUE {
  BUILDING_LENGTH = 100,
  VOLTAGE_RANGE = "208-230",
}

enum CATEGORY {
  CONVEYER = 'conveyers'
}
export enum ModalEvents {
  On3DShareLinkDeActivate = 'On3DShareLinkDeActivate'
}

@Component({
  selector: 'app-tunnel-configuration',
  templateUrl: './tunnel-configuration.component.html',
  styleUrls: ['./tunnel-configuration.component.scss'],
  providers: [CurrencyPipe]
})
export class TunnelConfigurationComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  conveyerCategory = CATEGORY.CONVEYER
  jobID: string;
  revisionID: string;
  aProduct: string
  aProductType: string = ''
  quoteLineId: string;
  productId: string;
  nodeId: string;
  disableReturnToJobButton: boolean;
  shortSummeryLength = 25; // number of characters to show
  configuratorData = {
    configurators: [],
    selectedProductData: {}
  }
  totalAmount = 0;
  selectedQuotePrice = 0;
  productDescription: string;
  init$: Subscription[] = [];
  copyNodeSubscription: Subscription;
  customerViewSubscription: Subscription;
  subscription$: Subscription[] = [];

  selectedOptionData: Products;
  selectedProductData: Products;
  showTunnelProductDetails = false;

  updateTarget: string;
  tunnelConfigurationData: Grouping;
  is3dViewOn = true;
  categories = [];
  allParts: any[];
  opportunity;
  quoteLine: VwsQuoteline;
  configurators;
  buildingLength;
  previousBuildingLength;
  voltageRange;
  is3dExpandViewOn = false;
  @ViewChild('categoryTabs') categoryTabs: ElementRef;
  currentCategoryTabIndex = 0;
  configBannerData: Grouping[] = [];
  specialDiscountBannerData: Grouping[] = [];
  selectedCategory: Categories;
  @Output() mainGroup;
  showAddProductsDetails = false;
  showCancel = false;
  PART_SPINNER = 'partOperations';
  availableProducts;
  addedQuoteLines = [];
  quoteLines = []
  sourceData;
  newlyAddedProduct = false;
  isConfigurationChange: boolean;
  configCSSClasses = {
    "styleAttribute": "cds-attribute",
    "styleSetDefault": "cds-attribute-value",
    "styleSetMouseOver": "cds-hovered",
    "styleSetSelected": "cds-selected",
    "styleSetMouseConflict": "cds-conflicted",
    "styleSetConflict": "cds-disabled",
    "styleSetDefaultValue": "cds-attribute-value-default",
    "styleRangeDefault": "cds-range-option-constraint",
    "styleRangeMouseConflict": "cds-range-option-constraint-conflicted",
    "styleRangeConflict": "cds-range-option-constraint-constrained"
  };
  initialLoad: boolean = true;
  visualiserInitialLoad: boolean = true;
  reloadRequiredOnToggle: boolean = true;
  isExpandViewOn = false;
  productID: any;
  loadingData: boolean = true;
  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cartService: CartService,
    private salesforce: SalesforceProxyService,
    private userService: UsersService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this.productService.expand3dViewSubject.subscribe({
      next: (value: boolean) => { this.is3dExpandViewOn = value; },
      error: (err) => { console.log(err); }
    });
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
  }

  ngOnInit(): void {
    this.setConfigurator();
    this.init$.push(this.route.params.subscribe({
      next: params => {
        this.processParams(params);
      },
    }));
    this.init$.push(this.route.queryParams.subscribe({
      next: params => {
        this.processQueryParams(params);
      },
    }));
    this.init$.push(this.productService.expand3dViewSubject.subscribe({
      next: (value: boolean) => { this.is3dExpandViewOn = value; },
      error: (err) => { console.log(err); }
    }))
    this.subscribeTo3DView();
  }

  onExpandButtonClick() {
    this.isExpandViewOn = !this.isExpandViewOn;
    this.productService.expand3dViewSubject.next(this.isExpandViewOn);
  }

  subscribeTo3DView() {
    this.subscription$.push(this.userService.get3DView()
      .subscribe({
        next: isActive => this.toggleVisualiser(isActive),
        complete: () => this.subscribeTo3DView(),
      }));
  }

  toggleVisualiser(isActive) {
    if (this.visualiserInitialLoad && isActive) {
      this.reloadRequiredOnToggle = false;
    }
    if (this.reloadRequiredOnToggle && !this.is3dViewOn) {
      //window.location.reload();
      this.reloadCurrentRoute(this.productID);
    }
    this.is3dViewOn = isActive;
    this.visualiserInitialLoad = false;
  }

  private processParams(params: Params) {
    if (params?.jobID) {
      this.jobID = params.jobID;
    }
    if (params?.revisionID) {
      this.revisionID = params.revisionID;
    }
    if (params?.aProduct) {
      this.aProduct = params.aProduct;
      this.aProductType = ''
      const [productType, aProduct] = this.getTunnelOption(params.aProduct);
      this.aProductType = productType;
      this.aProduct = aProduct;
    }
  }

  /**
  *  Get category,product details and initialize configurator.
  */
  setConfigurator() {
    this.spinner.show();
    this.subscription$.push(this.getOptionsAndProductDetails().subscribe({
      next: (res: [any, any[]]) => {
        this.getQuotes();
        this.categories = [];
        const categories = res[1];
        categories.map((category) => {
          if (!category.Products.every(p => p.Id === null)) {
            this.categories.push(category);
          }
        })
        this.selectedCategory = this.categories[0];
        this.availableProducts = this.categories[0].Products;
        this.mainGroup = this.categories[0];

        this.showTunnelProductDetails = false;
        let initialOptions = {
          'aProduct': this.aProduct
        };
        this.configurators = [];
        this.sourceData = res[0].main;
        cds.vws.init(this.configurators, this.sourceData, initialOptions);
        this.configuratorData.configurators = this.configurators;

        cds.vws.onChange3DHandler = (configurator) => {
          this.updateQuoteLine(configurator, configurator.quoteLineId);
        }
      },
      error: (err) => {
        this.spinner.hide();
      }
    }));
  }

  /**
   * @param  name tunnel type
   * Based on tunnel type set product type and product
   */
  getTunnelOption(name) {
    switch (name) {
      case ConveyorizedTunnelOptions.legend: return ['Legend Tunnel', Tunnel.avProductLegend];
      case ConveyorizedTunnelOptions.spinLite: return ['SpinLite Tunnel', Tunnel.avProductSpinLite];
      case ConveyorizedTunnelOptions.spinRite: return ['SpinRite Tunnel', Tunnel.avProductSpinRite];
    }
  }

  /** 
  * Get list of products[quote line] added to quotes 
  */
  getQuotes() {
    this.spinner.show();
    this.loadingData = true;
    this.subscription$.push(this.cartService.getCpqObjectByIdCDS<any>(CpqObjects.Quote, this.revisionID).subscribe({
      next: (res) => {
        this.showCancel = false;
        this.buildingLength = res.BuildingLength__c || " ";
        this.previousBuildingLength = this.buildingLength;
        this.voltageRange = res.PowerSupply__c || " ";
        this.addedQuoteLines = [];
        const lines = res?.Lines || [];
        this.quoteLines = lines;
        this.getQuotelineEntriesForSelectedCategory(lines)
        const quote = lines.filter(x => this.selectedCategory.Products.some(p => p.Id === x.ProductId));
        this.totalAmount = res.TotalAmount;
        this.loadingData = false;
        if (quote.length) {
          this.showAddProductsDetails = false;
          this.addedQuoteLines = quote;
        } else {
          this.showAddProductsDetails = true;
        }
        this.spinner.hide();
        const [productType, aProduct] = this.getTunnelOption(res.Product__c);
        this.aProductType = productType;
        this.aProduct = aProduct;
        if (this.buildingLength === " " || this.voltageRange === " ") {
          this.buildingLength = this.buildingLength === " " ? DEFAULT_VALUE.BUILDING_LENGTH : this.buildingLength;
          this.previousBuildingLength = this.buildingLength;
          this.voltageRange = this.voltageRange === " " ? DEFAULT_VALUE.VOLTAGE_RANGE : this.voltageRange;
          this.onVoltageChange();
        }
      },
      error: (err) => {
        this.spinner.hide();
      }
    }));
  }

  getQuotelineEntriesForSelectedCategory(quotelines) {
    let count = 0;
    quotelines.forEach(ele => {
      this.subscription$.push(
        this.cartService.getCpqObjectByIdCDS<any>(CpqObjects.QuoteLine, ele.Id).subscribe({
          next: res => {
            count++;
            const attributeGroup = this.sourceData.attributes.map(ele => ele[1]);
            let product;
            for (let i = 0; i < this.categories.length; i++) {
              if (!this.categories[i].Products.find(part => part.Id === res.ProductId)) continue;
              product = this.categories[i].Products.find(part => part.Id === res.ProductId);
              if (product) break;
            }
            let initialOptions = this.prepareOptions(res['Entries'], attributeGroup);
            let config = cds.vws.getProductConfigurator(product, initialOptions);
            let FilteredConfig = this.configuratorData.configurators.filter(x => x.manager.getValue().aComponent[0]?.id === product.AttributeMap.aComponent && x.quoteLineId === res.Id)[0];
            if (!FilteredConfig) {
              config.quoteLineId = res.Id;
              this.configurators.push(config);
            }
            if (count === quotelines.length && this.initialLoad) {
              cds.vws.renderScene();
              this.initialLoad = false;
            }
          },
          error: err => {
            this.spinner.hide();
          }
        })
      )
    });
    this.configuratorData.configurators = this.configurators;
  }

  /**
   * Fetch opportunity detail to check crmId and export status
   */
  getOpportunity() {
    this.spinner.show();
    if (this.opportunity) {
      this.salesForceOpp();
    } else {
      this.subscription$.push(this.cartService.getCpqObjectByIdCDS<any>(CpqObjects.Opportunities, this.jobID).subscribe({
        next: (res) => {
          this.opportunity = res;
          this.salesForceOpp();
        },
        error: (err) => {
          this.spinner.hide();
        }
      }));
    }
  }

  /**
   * Create opportunity in salesforce based on crmId and export status
   */
  salesForceOpp() {
    if (this.opportunity.CrmId === null && this.opportunity.CrmExportStatus === CPQ_EXPORT_STATUS.NOT_EXPORTED) {
      this.subscription$.push(this.createSFDCOpp().subscribe({
        next: (res) => {
          this.opportunity = res;
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        }
      }));
    } else {
      this.spinner.hide();
    }
  }

  /**
   * Create opportunity when new quote-line is created
   */
  createSFDCOpp() {
    const isDistributor = this.opportunity?.Owner?.PartnerId ? true : false;
    let payload: any = {
      Name: this.opportunity?.Name,
      StageName: VwsSfdcOpportunityStage.DEFAULT,
      CloseDate: this.opportunity?.ShippingDate,
      LeadSource: SfdcLeadSource.CPQ,
      Projected_Ship_Date__c: this.opportunity?.ShippingDate,
      CPQ_Opportunity_Owner_Email__c: this.opportunity.Owner?.Email,
      Outside__c: isDistributor,
      CPQ_Job_Link__c: environment.B2CConfigs.redirectUrl + '/job/' + this.opportunity.Id
    }
    return this.salesforce.createOpportunity(payload).pipe(
      switchMap(result => {
        this.opportunity.CrmId = result.id;
        return this.cartService.updateObjectById(CpqObjects.Opportunity, this.jobID, {
          CrmId: result.id,
          Name: this.opportunity?.Name
        });
      }));
  }


  /**
  * On click of add parts show product list
  */
  openAddParts(category, event?: Event) {
    event?.preventDefault();
    this.showAddProductsDetails = true;
    this.showCancel = true
    this.mainGroup = category;
  }

  /**
  * On click of added product find respective quote-line id
  */
  onClickOfAddedParts(line) {
    this.quoteLineId = line.Id;
    const products = this.selectedCategory.Products || [];
    const part = products.find((part) => part.Id === line.ProductId);
    this.quoteLineId = line?.Id;
    this.selectedQuotePrice = line?.TotalSellingPrice;
    this.productDescription = line?.SelectedProduct.Description;
    this.openPart(part, true);
  }

  /**
  * On click of added product load configurator
  */
  openPart(part: Products, reConfigure?) {
    this.showCancel = false;
    this.selectedOptionData = part;
    this.showAddProductsDetails = false;
    if (!reConfigure) {
      this.createQuoteLine(part);
    } else {
      this.newlyAddedProduct = false;
      this.loadConfiguratorForSelectedProduct(part);
    }
  }


  /**
   * Create Quote-line for new product
   * Use existing quote line for conveyor
   * @param  {Products} part selected product
   */
  createQuoteLine(part: Products) {

    let createSFDCOpp = false;
    if (this.quoteLines.length === 0) {
      createSFDCOpp = true;
    }
    this.quoteLineId = undefined;
    this.spinner.show();
    const existedProduct = this.addedQuoteLines.find(x => x.ProductId === part.Id);
    const conveyor = this.categories.find((x) => x.CategoryId === 'conveyers');
    const conveyorProduct = conveyor.Products.find(p => p.Id === existedProduct?.ProductId);
    if (conveyorProduct?.Id) {
      this.newlyAddedProduct = false;
      this.quoteLineId = existedProduct?.Id;
      this.loadConfiguratorForSelectedProduct(part);
    } else {
      const productEntries = {
        'aProduct': this.aProduct,
        'aComponent': part.AttributeMap["aComponent"]
      }
      this.subscription$.push(this.cartService
        .createObject(CpqObjects.QuoteLine, {
          ParentQuoteId: this.revisionID,
          RootQuoteId: this.revisionID,
          ProductId: part?.Id,
          Name: part.Label,
          Entries: productEntries,
        }).subscribe({
          next: (quoteLineData: any) => {
            this.productDescription = quoteLineData?.SelectedProduct.Description;
            this.newlyAddedProduct = true;
            this.quoteLineId = quoteLineData.Id;
            this.loadConfiguratorForSelectedProduct(part);
            if (createSFDCOpp) {
              this.getOpportunity()
            }
            this.spinner.hide();

          },
          error: (err) => {
            this.spinner.hide();
          }
        }));
    }
  }

  /**
   * Called when configurations are saved for the product
   */
  loadTunnelConfig() {
    const [category] = this.categories.filter((item, index) => index === this.currentCategoryTabIndex);
    this.displaySelectedCategory(category);
    this.showTunnelProductDetails = false;
    this.isConfigurationChange = false;
    this.selectedProductData = {} as any;
    this.getQuotes();
  }


  /**
   * Delete configured product [quote-line]
   */
  deleteNode(nodeData): void {
    this.spinner.show(this.PART_SPINNER);
    this.subscription$.push(this.cartService.deleteObjectByObjectId(CpqObjects.QuoteLine, nodeData.Id).subscribe({
      next: res => {
        const products = this.selectedCategory.Products || [];
        const part = products.find((part) => part.Id === nodeData.ProductId);
        const index = this.configuratorData.configurators.findIndex(x => x.manager.getValue().aComponent[0]?.id === part.AttributeMap.aComponent && x.quoteLineId === nodeData.Id);
        if (index !== -1) {
          // remove from configurator as well
          this.configuratorData.configurators.splice(index, 1)
        }
        this.selectedQuotePrice = 0;
        this.addedQuoteLines = this.addedQuoteLines.filter(x => x.Id !== nodeData.Id);
        if (this.addedQuoteLines.length <= 0) {
          this.showAddProductsDetails = true;
        }
        this.getQuotes();
        this.spinner.hide(this.PART_SPINNER);
        cds.vws.renderScene();
      },
      complete: () => {
      },
      error: err => {
        this.spinner.hide(this.PART_SPINNER);
        this.toastr.error(
          'There is fatal error while deleting', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    }))
  }

  /**
   * Copy configured product [quote-line]
   */
  copyNode(nodeData): void {
    this.spinner.show(this.PART_SPINNER);
    const newName = `Copy of ${nodeData?.Name}`;
    // API is not returning OptionsSummary
    const payload = {
      Name: newName,
    }
    this.subscription$.push(this.cartService.copyObjectById(CpqObjects.QuoteLine, nodeData?.Id, payload).subscribe({
      next: (res) => {
        this.addedQuoteLines.push(res);
        const product = this.selectedCategory.Products.find(part => part.Id === res.ProductId);
        const attributeGroup = this.sourceData.attributes.map(ele => ele[1]);
        let initialOptions = this.prepareOptions(res['Entries'], attributeGroup);
        let config = cds.vws.getProductConfigurator(product, initialOptions);
        config.quoteLineId = res.Id;
        const position = config.manager.getValue().aPositionX;
        config.attributes['aPositionX'].setValue(Number(position) + 15);
        this.configurators.push(config);
        this.getQuotes();
        this.spinner.hide(this.PART_SPINNER);
        cds.vws.renderScene();
      },
      error: (err) => {
        this.spinner.hide(this.PART_SPINNER);
        this.toastr.error(
          'There is fatal error while copying jobs', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    }));
  }
  /**
   * @param  {Categories} category Based on selected category show product details
   */
  displaySelectedCategory(category: Categories) {
    if (!this.selectedCategory?.Products?.length) {
      this.openAddParts(category);
    }
  }

  goToNextCategoryTab(tabId: number): void {
    this.showAddProductsDetails = false;
    this.showTunnelProductDetails = false;
    const categoryCount = this.categories.length - 1;
    this.categoryTabs.nativeElement.scrollLeft += SCROLL_TABS_UNIT + SCROLL_INCREMENT;
    if (this.currentCategoryTabIndex < categoryCount) {
      this.currentCategoryTabIndex++;
      const [category] = this.categories.filter((item, index) => index === this.currentCategoryTabIndex);
      this.displaySelectedCategory(category);
    } else if (this.currentCategoryTabIndex === categoryCount) {
      this.currentCategoryTabIndex = FIRST_TAB;
      this.categoryTabs.nativeElement.scrollLeft -= SCROLL_TO_BASE;
      const [category] = this.categories;
      this.displaySelectedCategory(category);
      return;
    }
    this.onClickDisplayCategoriesParts(this.categories[this.currentCategoryTabIndex], this.currentCategoryTabIndex, true);
  }

  goToPrevCategoryTab(tabId: number): void {
    this.showAddProductsDetails = false;
    this.showTunnelProductDetails = false;
    this.categoryTabs.nativeElement.scrollLeft -= SCROLL_TABS_UNIT;
    if (this.currentCategoryTabIndex > FIRST_TAB) {
      this.currentCategoryTabIndex--;
      const [category] = this.categories.filter((item, index) => index === this.currentCategoryTabIndex);
      this.displaySelectedCategory(category);
    } else if (this.currentCategoryTabIndex === FIRST_TAB) {
      this.categoryTabs.nativeElement.scrollLeft -= SCROLL_TO_BASE;
      const [category] = this.categories;
      this.displaySelectedCategory(category);
      return;
    }
    this.onClickDisplayCategoriesParts(this.categories[this.currentCategoryTabIndex], this.currentCategoryTabIndex, true);
  }
  /**
   * @param  {} category selected category
   * @param  {number} index index of category
   * On click of category show respective details
   */
  onClickDisplayCategoriesParts(category, index: number, nextPrevious?: boolean) {
    if (index === this.currentCategoryTabIndex && !nextPrevious)
      return;
    this.selectedProductData = {} as any;
    this.showAddProductsDetails = true;
    this.currentCategoryTabIndex = index;
    this.showTunnelProductDetails = false;
    this.displaySelectedCategory(category);
    this.selectedCategory = category;
    this.availableProducts = category.Products;
    this.mainGroup = category;
    this.getQuotes();
  }

  /**
   * For existing product call quote-line api to get details
   * Render configurator
   * @param  {Products} product selected product
   */
  loadConfiguratorForSelectedProduct(product: Products) {
    this.spinner.show();
    let initialOptions = {
      'aProduct': this.aProduct,
      'aComponent': product.AttributeMap["aComponent"]
    };
    if (!this.newlyAddedProduct) {
      this.subscription$.push(this.cartService
        .getCpqObjectByIdCDS<any>(CpqObjects.QuoteLine, this.quoteLineId)
        .subscribe({
          next: (data) => {
            const attributeGroup = this.sourceData.attributes.map(ele => ele[1]);
            initialOptions = this.prepareOptions(data['Entries'], attributeGroup);
            this.renderConfigurator(product, data['Entries'], initialOptions)
            this.spinner.hide();
          },
          error: (err) => {
            this.spinner.hide();
          },
        }));
    } else {
      this.renderConfigurator(product, null, initialOptions);
      this.spinner.hide();
    }
  }

  /**
   * On selection of product render configurator with saved options
  */
  renderConfigurator(product, options, initialOptions) {
    this.showCancel = false
    this.configuratorData.selectedProductData = product
    let config = cds.vws.getProductConfigurator(product, initialOptions);
    if (!this.newlyAddedProduct) {
      let FilteredConfig = this.configuratorData.configurators.filter(x => x.manager.getValue().aComponent[0]?.id === product.AttributeMap.aComponent && x.quoteLineId === this.quoteLineId)[0];
      if (!FilteredConfig) {
        config.quoteLineId = this.quoteLineId;
        this.configurators.push(config);
        cds.vws.renderScene();
      } else {
        config = FilteredConfig;
      }
      config.renderConfigurator(this.selectedCategory.CategoryId, "cds-config-options");
    }
    else {
      // Set defaults
      const configuredOptions = config.manager.getValue();
      Object.keys(configuredOptions).forEach(key => {
        const selectedAttributes = configuredOptions[key][0]?.attribute
        const defaultValue = selectedAttributes?.defaultValue;

        if (defaultValue && defaultValue !== null && !selectedAttributes?.isHiddenFromNesting) {
          if (typeof (defaultValue) === 'object') {
            const value = defaultValue.find(x => !x.isHiddenFromNesting && !x?.constraints?.isConstrained() && !x.constraints?.isMouseEventConstrained());
            if (value) {
              selectedAttributes.setValue(value.id);
            }
          } else if (typeof (defaultValue) === 'string' || typeof (defaultValue) === 'number') {
            selectedAttributes.setValue(defaultValue);
          }
          if ((selectedAttributes?.id === "aConveyorLength" || selectedAttributes?.id === "aDollieSpacing")) {
            let attr = config.manager.getAttribute("aConveyorLength");
            let length = (attr) ? attr.value : 0;
            attr = config.manager.getAttribute("aDollieSpacing");
            let v = attr.selectedValue?.id;
            if (selectedAttributes?.id === "aConveyorLength") {
              const d = attr.values.find(x => x.isSelected === true);
              v = d?.id;
            }
            let space = (attr && attr.hasSelection()) ? v : null;
            this.calculateNumDollies(config, space, length);
          }
        }
      })
      config.quoteLineId = this.quoteLineId;
      this.configurators.push(config);
      cds.vws.renderScene();
      config.renderConfigurator(this.selectedCategory.CategoryId, "cds-config-options");
    }

    this.showTunnelProductDetails = true;
    this.selectedProductData = product;
    config.manager.doPostEvent = (o, type, args) => {
      if (args === "interim_event") {
        return;
      }
      if ((type === "change" || type === 'click')) {
        this.isConfigurationChange = true;
        if ((o?.id === "aConveyorLength" || o?.attribute?.id === "aDollieSpacing")) {
          let attr = config.manager.getAttribute("aConveyorLength");
          let length = (attr) ? attr.value : 0;
          attr = config.manager.getAttribute("aDollieSpacing");
          let v = o?.id;
          if (o?.id === "aConveyorLength") {
            const d = attr.values.find(x => x.isSelected === true);
            v = d?.id;
          }
          let space = (attr && attr.hasSelection()) ? v : null;
          this.calculateNumDollies(config, space, length);
        }
        cds.vws.renderScene();
      }
    }
  }

  calculateNumDollies(config, space, length) {
    let num = 0;
    if (space === "avDollieSpacing4") {
      num = length * 2 / 4;
    } else if (space === "avDollieSpacing7") {
      if (length === 85) {
        num = 24;
      } else if (length === 130) {
        num = 36;
      } else if (length === 115) {
        num = 32;
      } else {
        num = length * 2 / 7.334;
      }
    } else if (space === "avDollieSpacing14") {
      num = length * 2 / 14.667;
    }
    let attr = config.manager.getAttribute("aNumDollies");
    if (attr) {
      attr.setValue(Math.round(num));
    }
  }


  prepareOptions(ModelInformation, attributeGroup) {
    let filteredData = ModelInformation?.filter((x) => attributeGroup.includes(x.OptionGroupId));
    filteredData = filteredData.sort((a, b) => attributeGroup.indexOf(a.OptionGroupId) - attributeGroup.indexOf(b.OptionGroupId));
    return filteredData.reduce((acc, x) => {
      acc[x.OptionGroupId] = x.ValueString || x.ValueDouble;
      return acc;
    }, {});
  }

  onVoltageChange(buildingLengthChange?) {
    let data: any = {
      "PowerSupply__c": this.voltageRange,
      "BuildingLength__c": this.buildingLength
    };
    this.spinner.show();
    this.subscription$.push(this.cartService
      .updateObjectById(CpqObjects.Quote, this.revisionID, data)
      .subscribe({
        next: () => {
          if (buildingLengthChange) {
            cds.vws.renderScene();
          }
        },
        complete: () => {
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        },
      }));
  }

  onBuildingLengthChange() {
    switch (true) {
      case (this.buildingLength < 78):
        this.toastr.warning(
          'The minimun value is 78.', 'Warning', {
          disableTimeOut: true,
          closeButton: true,
        });
        this.buildingLength = this.previousBuildingLength;
        return;
      case (this.buildingLength > 200):
        this.toastr.warning(
          'The maximun value is 200.', 'Warning', {
          disableTimeOut: true,
          closeButton: true,
        });
        this.buildingLength = this.previousBuildingLength;
        return;
      default:
        this.previousBuildingLength = this.buildingLength;
        this.onVoltageChange(true);
    }
  }

  resetView() {
    cds.vws.resetView();
  }

  driveThroughTunnel() {
    cds.vws.driveThroughTunnel()
  }

  viewAR() {
    cds.vws.viewAR();
  }

  closeQR() {
    cds.vws.closeQR()
  }

  async share3D() {
    const url = await cds.vws.getShare3DLink();
    const data: SaveDataRCresponse = {
      link: url,
    }
    this.openShare3DModel(data);
  }


  openShare3DModel(data: any) {
    const instance = this.modalService.open(Share3dComponent, {
      size: 'lg'
    });
    instance.componentInstance.data = data;
    instance.result.then(
      async (outcome) => {
        if (outcome.success === ModalEvents.On3DShareLinkDeActivate) {
          await cds.vws.deleteShare3DLink();
        }
      },(dismiss) => {}
    );
  }

  /**
   * update quote-line on click of save configuration
   */
  updateQuoteLine(config, quoteLineId) {
    let positionVal = config.manager.getValue()['aPositionX'];
    let data: any = {
      OptionGroupId: 'aPositionX',
      Value: positionVal
    };
    this.subscription$.push(this.cartService
      .updateObjectById(CpqObjects.QuoteLineEntry, quoteLineId, data)
      .subscribe((res) => { }));
  }

  ngOnDestroy() {
    this.init$.forEach(sub => sub.unsubscribe());
    this.subscription$.forEach(sub => sub.unsubscribe());
    this.productService.expand3dViewSubject.next(false);
  }
  getOptionsAndProductDetails() {
    const categories = this.cartService.getProductCategories();
    const optionList = this.cartService.getConfigOptions();
    return forkJoin([optionList, categories]);
  }

  reloadCurrentRoute(productID: any) {
    this.router.navigate([this.jobID, this.revisionID, 'choose', 'configuration'],
      { queryParams: { update: CpqObjectType.Product, productID }, skipLocationChange: true }
    );
  }

  private processQueryParams(params: Params) {
    if (params?.productID) {
      this.productID = params.productID;
    }
  }
}
