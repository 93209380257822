import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@cpq-environments/environment';
import { Observable } from 'rxjs';

const Salesforce_API = {
  SALESFORCE: 'salesforce',
  OPPORTUNITY: 'opportunity',
  LEAD: 'lead',
  OBJECT: 'object',
  CURRENT_CONTACT: 'current-contact',
  ACCOUNT: 'account',
  ATTACHMENT: 'upload_attachment'
};

const SalesforceServiceVariables = {
  SALESFORCE_PATH: 'salesforce',
  OPPORTUNITY_PATH: 'opportunity',
};
export enum SfdcLeadSource {
  CPQ = 'CPQ'
}

export enum VwsSfdcOpportunityStage {
  DEFAULT = 'Needs Assessment',
  CLOSED = 'Closed Won'
}

export interface SfdcOpportunity {
  Name: string;
  StageName: string;
  CloseDate: Date;
  CPQaccountNumber?: string;
  SFDCaccountField?: string;
  LeadSource?: SfdcLeadSource;
  Pricebook2Id?: string;
}

export interface VwsSfdcOpportunity extends SfdcOpportunity {
  Projected_Ship_Date__c: Date;
  CPQ_Opportunity_Owner_Email__c?: string;
  Outside__c?: boolean;
}

export interface TwgSfdcOpportunity extends SfdcOpportunity {
  CPQ_Quote_Id__c: string;
  Estimated_Close_Value__c: number;
}

export enum sfdcObjectType {
  LEAD = 'Lead',
  OPPORTUNITY = 'Opportunity',
  TASK = 'Task',
  CONTACT = 'Contact',
  ACCOUNT = 'Account'
}

@Injectable(
  { providedIn: 'root' }
)
export class SalesforceProxyService {
  constructor(
    private http: HttpClient) { }

  private backendUrl = environment.B2CConfigs.BackendURL;

  createOpportunity(payload: SfdcOpportunity): Observable<SalesforceResult> {
    return this.http.post(`${this.backendUrl}/${SalesforceServiceVariables.SALESFORCE_PATH}/${sfdcObjectType.OPPORTUNITY}`, payload);
  }

  createSfdcObject(sfdcObjectType: sfdcObjectType, payload: any): Observable<SalesforceResult> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType);
    return this.http.post(url, payload);
  }

  getSfdcAccountById(sfdcAccountId: string): Observable<any> {
    const url = this.salesforceUrl(Salesforce_API.ACCOUNT, sfdcAccountId);
    return this.http.get(url);
  }


  getSfdcObjectById(sfdcObjectType: sfdcObjectType, objectId: string): Observable<any> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType, objectId);
    return this.http.get(url);
  }
  updateSfdcObjectById(sfdcObjectType: sfdcObjectType, objectId: string, payload): Observable<SalesforceResult> {
    const url = this.salesforceUrl(Salesforce_API.OBJECT, sfdcObjectType, objectId);
    return this.http.put(url, payload);
  }

  updateOpportunity(opportunityId: string, payload): Observable<SalesforceResult> {
    const url = this.salesforceUrl(sfdcObjectType.OPPORTUNITY, opportunityId);
    return this.http.put(url, payload);
  }

    /**
   * Uploads any attachment to Salesforce Object
   * @param crmObjectId - Id of the Salesforce Object
   * @param file - Blod data as Form data
   * @returns Id of the salesforce attachment
   */
    uploadAttachmentToSfdc(crmObjectId: string, file): Observable<any> {
      const formData: FormData = new FormData();
      formData.append('file', file);
  
      const url = this.salesforceUrl(Salesforce_API.OBJECT, crmObjectId, Salesforce_API.ATTACHMENT);
      return this.http.post<any>(url, formData, { withCredentials: true });
    }


  salesforceUrl(...args: string[]): string {
    let url = `${this.backendUrl}/${Salesforce_API.SALESFORCE}`;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < args.length; i++) {
      if (args[i] != null) {
        // Do not append null or undefined; doesn't stop empty strings
        url += '/' + args[i];
      }
    }

    return url;
  }

}

export interface SalesforceResult {
  errors?: Array<any>;
  id?: string;
  externalId?: string;
}
