import { Injectable } from '@angular/core';
import { CpqOpportunity, CpqProposal, CpqQuoteline, QuoteWorkflowStatus } from '@cpq-app/services/cart.service';
import { CadData as BaseCadData } from '@cpq-app/services/product.service';
import { CPQ_EXPORT_STATUS, CpqAccount, CpqFavorite } from '../Cpq.interfaces.service';


@Injectable({
    providedIn: 'root',
})
export class VWSInterfacesService {

    constructor() { }
}

//TODO: how to get library opportunity ID ?
export const LibraryOpportunity = "08a00014457bbc09";
export interface VwsDtJobs {
    field?: string;
    header?: string;
    visibility?: boolean;
    value: (record: VwsJobs) => any;
}
export interface VwsDtAccounts {
    field?: string;
    header?: string;
    visibility?: boolean;
    value: (record: VwsAccounts) => any;
}
export interface VwsDtRevision {
    field?: string;
    header?: string;
    visibility?: boolean;
    control?: string;
    value: (record: VwsRevisions) => any;
}

export interface VwsJobs {
    LastModifiedDate?: string;
    Description?: string;
    AccountId?: AccountId;
    PrimaryQuote_TotalAmount?: any;
    PrimaryQuote_ProductsCount?: any;
    ExternalId?: string;
    PrimaryQuoteId?: string;
    IsClosed?: boolean;
    CurrencyIsoCode?: string;
    Name?: string;
    CreatedById: CreatedById;
    OwnerId?: OwnerId;
    CreatedDate?: string;
    Id?: string;
}

export interface AccountId {
    Id?: string;
    Name?: string;
}

export interface CreatedById {
    Id?: string;
    Name?: string;
}

export interface OwnerId {
    Id?: string;
    Name?: string;
}

export interface VwsAccounts {
    LastModifiedDate: string;
    OwnerId?: OwnerId;
    ExternalId?: string;
    Id: string;
    Name: string;
    AccountNumber?: string;
}

export interface VwsRevisions {
    LastModifiedDate?: string;
    ExpirationDate?: string;
    IsPrimary: boolean;
    fpRules?: FpRules;
    Id?: string;
    TotalAmount?: number;
    FormattedId?: string;
    CurrencyIsoCode?: string;
    Proposals?: Proposals;
    Name?: string;
    Description?: string;
    WorkflowStatus?: string;
    Note?: any;
    CreatedDate?: string;
    PO_Number__c?: string;
}

export interface Acl {
    canEdit: boolean;
    canDelete: boolean;
}

export interface Status {
    statusLabel: string;
}

export interface Wf {
    canExport: boolean;
    canAddQuote: boolean;
    canAddProduct: boolean;
    showMoreInfo: boolean;
    canEdit: boolean;
    canMakePrimary: boolean;
    canCopy: boolean;
    canDelete: boolean;
    canAddProposal: boolean;
    canPreview: boolean;
    status: Status;
}

export interface FpRules {
    acl: Acl;
    updateAvailable: boolean;
    isDependentDataOutOfSync: boolean;
    wf: Wf;
}

export interface Proposals {
    size: number;
}

export interface IFamilies {
    addToQuote: boolean;
    description: string;
    id: string;
    imageUrl: string;
    name: string;
}

export class VwsCpqFavorite extends CpqFavorite {
    static queryFields = ['graphicUrl__c'];

    graphicUrl__c: string;
}

export class VwsFavoritesFilter {
    static filterFields = ['parentproductId', 'null'];
    filters: string;
    addonFields: string;
    sort: string;
    resolveNames: string;
}

export interface NodeConfig {
    classname: string;
    id: number;
    name: string;
    category: string;
    value?: number;
    selected: number;
    quantity: number;
    label: string;
    unitPrice: number;
    netPrice: number;
    ExtDescription: string;
    imageUrl: string;
    available?: boolean;
    disabled: number;
    nodeId?: string;
    nodePosition?: number;
    modelCode?: string;
    selectionType?: string;
    optionSummary?: string[];
    isConfigurable?: boolean;
    isCopyable?: boolean;
    isDeleteable?: boolean;
    isMoveable?: boolean;
}

export interface SubGroup {
    classname: string;
    id: number;
    name: string;
    desc: string;
    label: string;
    minimum: number;
    maximum: number;
    uiView: string;
    subGroups: any[];
    options: NodeConfig[];
}

export interface MainGroup {
    classname: string;
    id: number;
    name: string;
    desc: string;
    label: string;
    minimum: number;
    maximum: number;
    uiView: string;
    subGroups: SubGroup[];
    options: any[];
}

export interface ActiveNode {
    nodeId: string;
    classname: string;
    id: number;
    name: string;
    category: string;
    value?: any;
    selected: number;
    quantity: number;
    label: string;
    totalPrice: number;
    ExtDescription: string;
    imageUrl: string;
    disabled: number;
    discountedPrice: string;
}

export interface Groups {
    mainGroup: MainGroup;
}

export interface Result {
    groups: Groups;
    activeNode: ActiveNode;
}

export interface GropNodeConfig {
    result: Result;
}

export interface QuoteLine {
    ExtendedDescription?: string;
    LastModifiedDate?: string;
    Description?: string;
    TotalSellingPrice?: number;
    Product_Id: string;
    OrderCode: string;
    Quantity: number;
    IsSelected: boolean;
    Id: string;
    CurrencyIsoCode: string;
    Name: string;
    ProductId?: ProductId;
}

export interface ProductId {
    Id?: string;
    Name?: string;
}

export interface CadMaterial {
    color: string;
    title: string;
    type: string;
    emissive?: string;
    roughness?: number;
    metalness?: number;
    clearCoat?: number;
    clearCoatRoughness?: number;
    reflectivity?: number;
    opacity?: number;
    transparent?: boolean;
    map?: string;
    bumpMap?: string;
    bumpScale?: number;
    side?: number;
}

export interface CadScene {
    concreteLength: number;
    concreteWidth: number;
    concreteDepth: number;

    wall?: {
        length: number;
        width: number;
        height: number;
        offset: number;
    };

    trench?: {
        length: number;
        width: number;
        depth: number;
        distFromStart: number;
        distFromLeft: number;
    };
}

export interface CadLocation {
    x?: number;
    y?: number;
    z?: number;
}

export interface CadOrientation {
    x?: number;
    y?: number;
    z?: number;
}

export interface CadPartOptions {
    powder_coat?: string;
    powder_coat_secondary?: string;
}

export interface CadSelectedOptions {
    key: string;
    value: string | number | boolean;
}

export interface CadPart {
    product: string;
    nodeId: number;
    position: CadLocation;
    orientation?: CadOrientation;
    isInteractive: boolean;
    boundariesMin?: CadLocation;
    boundariesMax?: CadLocation;
    partOptions?: CadSelectedOptions[];
    selectedOptions?: CadSelectedOptions[];
    layer: string;
    partSize: number;
}

export interface CadData extends BaseCadData {
    materials?: { [key: string]: CadMaterial };
    scene: CadScene;
    parts: CadPart[];
}

export class VwsQuoteline extends CpqQuoteline {
    TotalNetPrice__c: number;
}

/**
 * Temporary placeholder class while the query vs object-fetch API is re-written. This should be removed once
 * the base class in the service is restored to match the plain type used by the new fetch methods.
 */
export class TempCpqQuote {
    static queryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
        'FormattedId', 'Note', 'ExpirationDate', 'CurrencyIsoCode', 'Selling_Price__c', 'ExpirationTime',
        CpqProposal.getNestedQueryString(),
    ];

    static guestQueryFields = ['Id', 'Name', 'WorkflowStatus', 'LastModifiedDate',
        'FormattedId', 'Note', 'ExpirationDate', 'ExpirationTime',
        CpqProposal.getNestedQueryString(),
    ];

    // tslint:disable: variable-name
    CreatedDate: string;
    ExpirationDate: string | Date;
    ExpirationTime: string;
    FormattedId: string;
    fpRules?: FpRules;
    Id: string;
    IsPrimary: number|boolean;
    LastModifiedDate?: string;
    Name: string;
    Note: string;
    WorkflowStatus: QuoteWorkflowStatus;
    CurrencyIsoCode: string;

    // These fields may not be available to guests
    Selling_Price__c?: string;
    // tslint:enable: variable-name

    // These are nested objects
    QuoteLines: CpqQuoteline[];

    QuoteConfiguredProducts?: any[];
    products?: any[];

    Proposals?: CpqProposal[];
    proposal?: CpqProposal;
    CreatedTime?: string;


    static getQueryString(): string {
        return `SELECT ${this.queryFields.join(', ')} FROM Quote`;
    }

    static getNestedQueryString(): string {
        return `(SELECT ${this.queryFields.join(', ')} FROM Quotes)`;
    }

    /**
     * Retruns `true` if the quote object has a closed workflow state
     * @param quote as `CpqQuote`
     */
    static checkIsClosed(quote: TempCpqQuote) {
        switch (quote?.WorkflowStatus) {
            case QuoteWorkflowStatus.None:
            case QuoteWorkflowStatus.NoApprovalRequired:
            case QuoteWorkflowStatus.InProgress:
            case QuoteWorkflowStatus.Aborted:
            case QuoteWorkflowStatus.Canceled:
            case QuoteWorkflowStatus.Invalid:
            case QuoteWorkflowStatus.Rejected:
                return false;

            case QuoteWorkflowStatus.Completed:
            case QuoteWorkflowStatus.Approved:
            case QuoteWorkflowStatus.Waiting:
            case QuoteWorkflowStatus.AutoApproved:
            default:
                return true;
        }
    }

    constructor(maybeQuote?: TempCpqQuote) {
        // console.log('%c*** Made a CpqQuote', 'background-color:red');
        this.Id = maybeQuote?.Id;
        this.Name = maybeQuote?.Name;
        this.WorkflowStatus = maybeQuote?.WorkflowStatus;
    }

    get id() {
        return this.Id;
    }

    get name() {
        return this.Name;
    }

    get workflowStatus() {
        return this.WorkflowStatus;
    }

    get isClosed(): boolean {
        switch (this.WorkflowStatus) {
            case QuoteWorkflowStatus.None:
            case QuoteWorkflowStatus.NoApprovalRequired:
            case QuoteWorkflowStatus.InProgress:
            case QuoteWorkflowStatus.Aborted:
            case QuoteWorkflowStatus.Canceled:
            case QuoteWorkflowStatus.Invalid:
            case QuoteWorkflowStatus.Rejected:
                return false;

            case QuoteWorkflowStatus.Completed:
            case QuoteWorkflowStatus.Approved:
            case QuoteWorkflowStatus.Waiting:
            case QuoteWorkflowStatus.AutoApproved:
            default:
                return true;
        }
    }
}

export class VwsCpqQuote extends TempCpqQuote {
    static queryFields = ['TotalAmount', 'SiteVoltage__c', 'SiteDriveSide__c',
        'TaxAmount__c', 'TaxRequired__c', ...TempCpqQuote.queryFields];

    static guestQueryFields = ['SiteVoltage__c', 'SiteDriveSide__c', 'TaxAmount__c', 'TaxRequired__c', ...TempCpqQuote.guestQueryFields];

    static addOnFields = ['SiteVoltage__c', 'SiteDriveSide__c', 'TaxAmount__c', 'TaxRequired__c'];

    // tslint:disable: variable-name
    TotalAmount: number;
    SiteVoltage__c: string;
    SiteDriveSide__c: string;
    TaxAmount__c: string;
    TaxRequired__c: boolean;
    Product__c: string;
    Scope__c: string;
    Sequence__c: number;

    fpRules?: FpRules;
    IsPrimary: number|boolean;
//  LastModifiedDate?: string;
    ModifiedTime?: string
    // tslint:enable: variable-name
    QuoteConfiguredProducts: QuoteConfiguredProducts[];

    QuoteLines: VwsQuoteline[];
    Lines: VwsQuoteline[];
    PO_Number__c:string

    // static quoteStatus(quote: VwsCpqQuote): QuoteStatus {
    //     if (quote?.status__c) {
    //         switch (quote.status__c) {
    //             case QuoteWorkflowStatus.None:
    //             case QuoteWorkflowStatus.Waiting:
    //             case QuoteWorkflowStatus.NoApprovalRequired:
    //             case QuoteWorkflowStatus.InProgress:
    //                 return QuoteStatus.Active;

    //             case QuoteWorkflowStatus.Approved:
    //             case QuoteWorkflowStatus.AutoApproved:
    //             case QuoteWorkflowStatus.Completed:
    //                 return QuoteStatus.Submitted;

    //             case QuoteWorkflowStatus.Aborted:
    //             case QuoteWorkflowStatus.Canceled:
    //             case QuoteWorkflowStatus.Invalid:
    //             case QuoteWorkflowStatus.Rejected:
    //             default:
    //                 return QuoteStatus.Inactive;
    //         }   
    //     } else {
    //         return QuoteStatus.Inactive;
    //     }

    // }
}

// export enum QuoteStatus {
//     Inactive = 'Inactive',
//     Submitted = 'Submitted',
//     Active = 'Active'
// }

export interface QuoteConfiguredProducts {
    Id: string;
    NodeIndex: number;
    OpportunityId: string;
    TotalList: number;
}

export class VwsCpqOpportunity extends CpqOpportunity {
    static addOnFields = ['Note__c', 'ShippingDate', 'PrimaryQuote.WorkflowStatus', 'PrimaryQuote.ExpirationDate'];

    // tslint:disable: variable-name
    Description: string;
    CurrencyIsoCode: string;
    OwnerId: string;
    AccountId: string;
    Note__c: string;
    ShippingDate: string;
    Quotes?: VwsCpqQuote[];
    ExternalId?: string;
    SiteDriveSide__c: string;
    CrmExportStatus: CPQ_EXPORT_STATUS;
    // tslint:enable: variable-name
}


export interface AvailableProduct {
    classname: string;
    id: number;
    name: string;
    contextHelp: string;
    desc: string;
    label: string;
    extendedDesc: string;
    longDescription: string;
    graphicName: string;
    minimum: number;
    maximum: number;
    selected: number;
    SelectionType: string;
}


// tslint:disable-next-line: no-empty-interface
export interface CpqRevisions extends VwsCpqQuote {  // TODO: replace / depreciate or make into an alias

}
interface PrimaryQuoteId {
    Id: string;
    Name: string;
}
export interface VwsJobRevision {
    LastModifiedDate: string;
    Description?: string;
    AccountId: AccountId;
    PrimaryQuote_TotalAmount: number;
    PrimaryQuote_ProductsCount: number;
    ExternalId?: any;
    PrimaryQuoteId: PrimaryQuoteId;
    Quotes: VwsRevisions[];
    IsClosed: boolean;
    CurrencyIsoCode: string;
    Name: string;
    CreatedById: CreatedById;
    OwnerId: OwnerId;
    CreatedDate: Date;
    Id: string;
}

export interface VwsCpqAccount extends CpqAccount {
    Fax__c: string;
    Phone__c: string;
    Email__c: string;
    PricingTier: string;
    Terms_Conditions__c: string;
}

export const QueryParams = {
    Update: 'update',
    ProductId: 'productID',
};

export interface TunnelOptions {
    Voltage: string;
    DriveSide: string;
    TunnelLength: number;
}


export interface Categories {
    Category: string;
    CategoryId: string;
    ImageUrl: string;
    Products: Products[];
}

export interface Products {
    AttributeMap: AttributeMap;
    Category: string;
    CategoryId: string;
    Id: string;
    ImageUrl: string;
    Label: string
}
interface AttributeMap {
    aComponent: string;
    legend_tunnel: string;
    spinlite_tunnel: string;
    spinrite_tunnel: string
}

export enum Tunnel {
    avProductLegend = 'avProductLegend',
    avProductSpinLite = 'avProductSpinLite',
    avProductSpinRite = 'avProductSpinRite'
}

export enum ConveyorizedTunnelOptions {
   legend = 'Legend Conveyorized Tunnel',
   spinLite = 'Spinlite Conveyorized Tunnel',
   spinRite = 'Spinrite Conveyorized Tunnel'
}