<!-- <div class="jobs-summary-block mt-4 mb-4">
    <div class="vws-header job-header">Jobs</div>
    <div class="job-add-action ">
        <button [disabled]='disableAddJobButton' (click)="createJobAndRevisionWithDefaultValues()" type="button" class="add-job-button">
            <i class="fa fa-plus"></i>
            <span class="ml-2"> New Job</span>
        </button>
    </div>
</div> -->
<div class="mt-3">
<app-datatable [GridData]="jobs" [ColData]="columnDefinitions$ | async" [loading]="dataLoading"
    [pageHeaderInformation]="pageHeaderInformation"(buttonClick) = "buttonClick($event)"></app-datatable>
</div>
