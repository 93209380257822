import { Injectable } from '@angular/core';

import { CPQRoutes } from './TWG/cpq-routing.module';
import { VWSRoutes } from './VWS/vws-routing.module';
import { MSALService } from '../shared/services/msal.service';

@Injectable()
export class TenantRoutingService {
    static getTenantRoutes() {
        let TenantRoues = [];
        let msalService = new MSALService();
        let user = msalService.getUser();
        //In future, we can access required token to get the opco related information
        if (localStorage.getItem('logged_in') != 'true') {
            TenantRoues = VWSRoutes;
        } else {
            TenantRoues = VWSRoutes
        }
        return TenantRoues;
    }
}