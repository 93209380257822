import { Directive, Input, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[appDebounce]'
})
export class DebounceDirective implements OnInit {
  @Input() debounce: number;
  @Output() valueChange = new EventEmitter();

  constructor(private el: ElementRef) { }

  ngOnInit(){
    fromEvent(this.el.nativeElement, 'input').pipe(
      debounceTime(this.debounce), distinctUntilChanged()).subscribe(() => {
        this.valueChange.emit(this.el.nativeElement.value)
      });
  }
}