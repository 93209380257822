<nav class="navbar navbar-expand-sm third-header">
    <div class="container">
        <div class="row">
            <div class="col-md-4 OpCoSpecificLogo">
                <a class="navbar-brand mt-1" (click) = "homePage()">
                    <img src="assets/images/logo_vws_smartlink.png" class="OpCoSpecificLogo" alt="opw logo">
                </a>
            </div>
        </div>

        <p class="secondHeaderTextBelanger">DRIVE | CLEAN</p>
        <div class="navbar-mobile-view">
            <a style="cursor: pointer;" class="icon" (click)="openMenuDevice()">
                <i class="fa fa-bars"></i>
            </a>
        </div>
    </div>
</nav>