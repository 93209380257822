<nav class="navbar navbar-expand-sm administration-header body-section">
	<!-- <a class="navbar-brand displaymobile" href="#"></a> -->
	<button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
		aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		<a style="cursor: pointer;" class="icon">
			<i class="fa fa-bars"></i>
		</a>
	</button>
	<div class="col-md-4 OpCoSpecificLogo">
		<a class="navbar-brand mt-1" (click)="navigateToHome()">
			<img src="assets/images/logo_vws_smartlink.png" class="OpCoSpecificLogo" alt="opw logo">
		</a>
	</div>
	<p class="secondHeaderTextBelanger mt-3">DRIVE | CLEAN</p>
	<div class="collapse navbar-collapse" id="navbarSupportedContent">
		<ul class="navbar-nav">
			<li class="nav-item dropdown ml-3">
				<a class="nav-link" [href]="headerLogoUrl">CX Portal</a>
			</li>
			<ng-container *ngFor="let menuItem of data['menuItems']">
				<!-- With Submenus -->
				<ng-container *ngIf="menuItem['subMenus'].length > 0; else noSubMenus">
					<li class="nav-item dropdown ml-3">
						<a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
							aria-haspopup="true" aria-expanded="false">{{ menuItem["name"] }}<i class="arrow down"></i>
						</a>
						<ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
							<ng-container *ngFor="let submenu of menuItem['subMenus']">
								<li *ngIf="submenu['subMenus'].length > 0; else elseBlock1">
									<button class="dropdown-item parent-menu"
										[ngClass]="{ 'default-cursor': submenu['subMenus'].length > 0 }">{{submenu["name"]}}</button>
								</li>
								<ng-template #elseBlock1>
									<li><button class="dropdown-item"
											[routerLink]="[submenu['routerLink']]">{{menuItem["name"] === 'Setup' &&
											submenu["name"] === 'Organizations' ? 'Organization Types' :submenu["name"]
											}}</button>
									</li>
								</ng-template>
								<ng-container *ngIf="submenu['subMenus'].length > 0">
									<ng-container *ngFor="let submenu1 of submenu['subMenus']">
										<li>
											<button class="pl-5 submenu dropdown-item" [routerLink]="[submenu1['routerLink']]">
												{{ submenu1["name"] }}
											</button>
										</li>
									</ng-container>
								</ng-container>
							</ng-container>
						</ul>
					</li>
				</ng-container>
				<!-- With Submenus / -->
				<!-- Without Submenu -->
				<ng-template #noSubMenus>
					<li class="nav-item ml-3">
						<button class="nav-link">{{ menuItem["name"] }}</button>
					</li>
				</ng-template>
				<!-- Without Submenu / -->
			</ng-container>
			<!-- User -->
			<li class="nav-item dropdown ml-3" *ngIf="this.userName !== ''">
				<a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">{{ this.userName }}
					<i class="arrow down"></i>
				</a>
				<div class="dropdown-menu dropdown-menu-right">
					<button class="dropdown-item" (click)="openUserProfile()">Profile</button>
					<button class="dropdown-item" (click)="changePassword()">Change Password</button>
					<button class="dropdown-item" (click)="navigateToHome()">Dashboard</button>
					<button class="dropdown-item" (click)="navigateToAccountsPage()">Accounts</button>
					<button *ngIf="isAdmin" class="dropdown-item" (click)="navigateToConfiguredQuotes()">Manage
						Pre-configured Products</button>
			<li><button class="dropdown-item default-cursor parent-menu">Settings</button></li>
			<li>
				<button (click)="$event.stopPropagation()" class="pl-5 dropdown-item submenu">Customer View <i
						class="fa fa-info-circle info mr-1" title="This is to enable Customer View"></i>
					<label class="switch">
						<input type="checkbox" [checked]="isCustomerView" (change)="toggleCustomerViewMode($event)">
						<div class="slider round">
							<span class="on">ON</span>
							<span class="off">OFF</span>
						</div>
					</label>
				</button>
			</li>
			<li>
				<button (click)="$event.stopPropagation()" class="pl-5 submenu dropdown-item">3D View <i
						class="fa fa-info-circle info mr-1" title="This is to hide 3D View"></i>
					<label class="switch">
						<input type="checkbox" [checked]="is3DViewOn" (change)="toggle3DViewMode($event)">
						<div class="slider round">
							<span class="on">ON</span>
							<span class="off">OFF</span>
						</div>
					</label>
				</button>
			</li>
			<li ><button class="dropdown-item default-cursor parent-menu">Help</button></li>
			<li><button (click)="openTutorial()" class="pl-5 dropdown-item submenu">Tutorials</button></li>
			<li><button (click)="openFAQ()" class="pl-5 dropdown-item submenu">FAQ</button></li>
			<button *ngIf="personaName === 'DistributorAdmin'" class="dropdown-item"
				(click)="openDistributorProfile()">Distributor Profile</button>
	</div>
	</li>
	<!-- User / -->
	</ul>
	</div>
</nav>