<div class="modal-header library-header mt-2">
    <div class="header">Edit Pre-configured Product</div>
    <span class="close-icon" aria-hidden="true"><i class="fa fa-times mr-2 cursor-pointer" aria-hidden="true"
            (click)="activeModal.dismiss()"></i> </span>
</div>
<div class="library modal-body">
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="preConfiguredQuote">
                <ngx-spinner [fullScreen]="false" type="ball-scale-ripple" size="medium" [name]="SUBMIT_SPINNER">
                    <p class="loading">Please wait...</p>
                </ngx-spinner>
                <div class="form-group required child-control">
                    <label for="Name" class="control-label">Pre-Configured Product Name</label>
                    <input type="text" class="form-control" id="Name" formControlName="Name" placeholder=""
                        [ngClass]="{ 'is-invalid':  (preConfiguredQuote.controls['Name'].dirty || preConfiguredQuote.controls['Name'].touched) && preConfiguredQuote.controls['Name'].errors }">
                    <div
                        *ngIf="preConfiguredQuote.controls['Name'].invalid && (preConfiguredQuote.controls['Name'].dirty || preConfiguredQuote.controls['Name'].touched)">
                        <div *ngIf="preConfiguredQuote.controls['Name'].invalid && (preConfiguredQuote.controls['Name'].dirty || preConfiguredQuote.controls['Name'].touched)"
                            class="invalid-feedback">
                            <div *ngIf="preConfiguredQuote.controls['Name'].errors?.required">
                                Quote Name is required</div>
                            <div *ngIf="preConfiguredQuote.controls['Name'].errors?.pattern">
                                Quote Name must be a character</div>
                        </div>
                    </div>
                </div>


                <div class="form-group required required mt-3">
                    <label for="Sequence__c" class="control-label">Sequence<span></span></label>
                    <input type="text" class="form-control" id="Sequence__c" formControlName="Sequence__c"
                        [ngClass]="{ 'is-invalid':  (preConfiguredQuote.controls['Sequence__c'].dirty || preConfiguredQuote.controls['Sequence__c'].touched) && preConfiguredQuote.controls['Sequence__c'].errors }">
                    <div *ngIf="preConfiguredQuote.controls['Sequence__c'].invalid && (preConfiguredQuote.controls['Sequence__c'].dirty || preConfiguredQuote.controls['Sequence__c'].touched)"
                        class="invalid-feedback">
                        <div *ngIf="preConfiguredQuote.controls['Sequence__c'].invalid">
                            <div *ngIf="preConfiguredQuote.controls['Sequence__c'].errors.pattern ">
                                Sequence must be a number
                            </div>
                            <div *ngIf="preConfiguredQuote.controls['Sequence__c'].errors.required">
                                Sequence is required
                            </div>
                            <div *ngIf="preConfiguredQuote.controls['Sequence__c'].hasError('max')">Sequence cannot
                                be
                                greater than {{quoteInformation?.length + 1}}</div>
                            <div *ngIf="preConfiguredQuote.controls['Sequence__c'].hasError('min')">Sequence should
                                be
                                greater than 0</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 p-0 d-flex mt-3" *ngIf="quoteInformation?.scope !==null">
                    <span class="control-label">Internal Scope <i class="fa fa-info-circle info mr-4"
                            title="Enable only internal Scope"></i>
                        <label class="switch">
                            <input type="checkbox" [checked]="quoteInformation?.scope" formControlName="Scope__c">
                            <div class="slider round">
                                <span class="on" title="Internal Scope">Yes</span>
                                <span class="off" title="Internal and external Scope">No</span>
                            </div>
                        </label>
                    </span>
                </div>
                <div class="modal-footer d-flex mt-2">
                    <button type="button" class="btn btn-light" (click)="modal.dismiss()">Cancel</button>
                    <button type="button" class="btn primaryButton" [disabled]="preConfiguredQuote.invalid"
                        (click)="onSave()">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>