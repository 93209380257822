import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { SalesforceProxyService, SfdcLeadSource, VwsSfdcOpportunityStage } from '@cpq-app/services/salesforce.service';
import { SharedService } from '@cpq-app/shared/services/shared.service';
import { CpqAccounts, CpqJob } from '@cpq-app/tenants/Cpq.interfaces.service';
import { environment } from '@cpq-environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAccountsComponent } from '@vws-app/Accounts/create-accounts/create-accounts.component';
import { RouteNames } from '@vws-app/VWS';
import { VwsCpqOpportunity } from '@vws-app/VWS.interfaces.service';
import { endOfToday } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

const DEFAULT_CURRENCY_ISO = 'USD';
const POPUP_MODEL_SIZE = 'xl';
const PREDICTED_DATE_MIN_VALUE = 1;
const staticObject = {
  opportunity: 'Opportunity'
};
@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.scss']
})
export class CreateJobComponent implements OnInit, OnDestroy {

  createJobForm: UntypedFormGroup;
  currencyData = [DEFAULT_CURRENCY_ISO];
  submitted = false;
  editAccountForm = false;
  jobID: string;
  accountNames = [];
  revisionID: string;
  minPredictedDate: {
    year: number,
    month: number,
    day: number,
  };
  validationMessage: string;
  routeName: string;
  subscription$: Subscription[] = [];
  @Input() isOrderPlaced;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private salesforce: SalesforceProxyService
  ) {
    this.subscription$.push(this.route.params.subscribe(params => {
      if (params) {
        this.editAccountForm = true;
        this.jobID = params.jobID;
        const routeData = this.route.snapshot?.data;
        this.routeName = routeData?.routeName;
        this.revisionID = params.revisionID;
        this.init();
      }
    }));
  }

  ngOnInit(): void {}

  init() {
    const minDate = new Date();
    minDate.setDate((minDate.getDate()) + PREDICTED_DATE_MIN_VALUE);
    this.minPredictedDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate(),
    };
    this.initialFormData();
    if (this.editAccountForm) {
      this.updateDetail();
    } else {
      this.getAccountNames();
    }
    this.createJobForm.controls.CurrencyIsoCode.setValue(DEFAULT_CURRENCY_ISO);
    this.subscription$.push(this.cartService.accountsUpdated$.subscribe(
      res => {
        this.getAccountNames(); // Calling getAccountNames on Account Name update
      }
    ));

    this.subscription$.push(this.cartService.updateJobFromStepper$?.subscribe({
      next: () => this.onSave()
    }));

    this.subscription$.push(this.cartService.orderPlaced$.subscribe({
      next: (resp) => {
        if (resp) {
          this.editFormInitialData();
          this.cartService.orderPlaced$.next(false);
        }
      }
    }));
  }

  updateDetail() {
    this.spinner.show();
    const account = this.cartService.getCpqObjectsCDS<CpqAccounts[]>(CpqObjects.Accounts);
    const update = this.cartService.getCpqObjectByIdCDS<VwsCpqOpportunity>(CpqObjects.Opportunities, this.jobID);
    const join = forkJoin([account, update]);
    join.subscribe({
      next: ([account, opportunity]) => {
        this.accountNames = [...this.accountNames, ...account];
        this.setFormData(opportunity);
        this.spinner.hide();
      },
      error: err => {
        this.spinner.hide();
      }
    })

  }

  datePickerValidation() {
    this.subscription$.push(this.createJobForm.controls.ShippingDate.valueChanges.subscribe(date => {
      if (date && date?.year && date?.month && date?.day) {
        const dateSelected = `${date.year}/${date.month}/${date.day}`;
        const selectedDate = new Date(dateSelected);
        const minDate = `${this.minPredictedDate.year}/${this.minPredictedDate.month}/${this.minPredictedDate.day}`;
        const minDateValidation = new Date(minDate);
        this.validationMessage = (date?.year !== undefined && (selectedDate.getTime() < minDateValidation.getTime())) ? `Expected Ship Date must be after today's date` : 'Enter a valid Date';
      }
    }));
  }

  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.createJobForm = this.formBuilder.group({
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp), Validators.maxLength(120)]],
      Description: [null, Validators.maxLength(2000)],
      CurrencyIsoCode: [null, Validators.required],
      AccountId: [null],
      ShippingDate: [null, Validators.required],
      OpportunityOwner: [{ value: null, disabled: true }],
      Note__c: [null, Validators.maxLength(2000)],
    });
    this.datePickerValidation();
  }

  dateFormatter(date) {
    const dateSelected = `${date.year}/${date.month}/${date.day}`;
    const d = new Date(dateSelected);
    // removing the timezone offset.
    d.setHours(0, -d.getTimezoneOffset(), 0, 0);
    return d.toISOString();
  }

  convertDateStringToObject(date: string) {
    if (date) {
      const [year, month, day] = date.split('-');
      const obj = {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day.split(' ')[0].trim(), 10)
      };
      return obj;
    }
  }

  editFormInitialData() {
    this.spinner.show();
    this.subscription$.push(this.cartService.getCpqObjectByIdCDS<VwsCpqOpportunity>(CpqObjects.Opportunities, this.jobID)
      .subscribe({
        next: (opportunity: any) => {
          this.setFormData(opportunity);

          this.spinner.hide();
        },
        error: err => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  setFormData(opportunity) {
    const date = this.convertDateStringToObject(opportunity?.ShippingDate);
    this.createJobForm.controls.Name.setValue(opportunity?.Name);
    this.createJobForm.controls.Description.setValue(opportunity?.Description);
    this.createJobForm.controls.CurrencyIsoCode.setValue(opportunity?.CurrencyIsoCode);
    this.createJobForm.controls.CurrencyIsoCode.disable();
    if (date) {
      this.createJobForm.controls.ShippingDate.setValue(date);
    }
    this.createJobForm.controls.Note__c.setValue(opportunity?.Note__c);
    this.createJobForm.controls.OpportunityOwner.setValue(opportunity?.Owner?.Name);
    this.createJobForm.controls.AccountId.setValue(opportunity?.AccountId);
    this.warnIfPredictedDateTooSoon(opportunity?.ShippingDate);
  }

  warnIfPredictedDateTooSoon(shippingDate: string): void {
    let shipDate = new Date(shippingDate);

    if (shipDate.getTime() < endOfToday().getTime()) {
      this.toastr.warning('Expected Ship Date must be after today\'\s date', 'Warning');
    }
  }

  isFormValid() {
    return this.createJobForm.valid;
  }

  onSave() {
    this.submitted = true;
    try {
      if (this.isFormValid()) {
        if (this.editAccountForm) {
          this.updateJob();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  updateJob() {
    this.spinner.show();
    const formGroup = this.sharedService.trimFormFieldValues(this.createJobForm);
    const formValues = formGroup.value;
    formValues.ShippingDate = this.dateFormatter(formValues.ShippingDate);
    this.subscription$.push(this.cartService.updateObjectById(CpqObjects.Opportunities, this.jobID, { ...formValues }).pipe(
      switchMap((ele: CpqJob) => {
        return this.updateSFDCOpp(ele);
      })
    )
    .subscribe(
      (res) => {
        this.spinner.hide();
        /*
          Showing same form in 2 steps in the application, Need to show toaster on job edit page
          Step 1: Displaying form in job edit page => which don't have Config ID
          Step 2: Displaying form in Revision edit page => which has Config ID
        */
        if (!Boolean(this.revisionID)) {
          this.toastr.success(
            'Job Updated Successfully'
          );
        } else {
          /*
            Call updateQuoteFromStepper subject to save quote related information 
          */
          this.cartService.updateQuoteFromStepper$.next();
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(
          'There is fatal error while Updating Job', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }));
  }

  getAccountNames() {
    this.accountNames = [];
    this.subscription$.push(this.cartService.getCpqObjectsCDS<CpqAccounts[]>(CpqObjects.Accounts)
      .subscribe(
        (results: CpqAccounts[]) => {
          this.accountNames = [...this.accountNames, ...results];
        },
        err => {
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      ));
  }

  isNotQuoteEditPage(): boolean {
    return this.routeName !== RouteNames.REVISION_VIEW;
  }

  open() {
    this.modalService.open(CreateAccountsComponent, {
      size: POPUP_MODEL_SIZE
    });
  }

  redirectToLandingPage() {
    this.router.navigate(['']);
  }

  redirectToAccountView() {
    this.router.navigate([this.jobID, this.revisionID, 'accounts', 'new']);
  }

   /**
   * Update opportunity when Job is updated
   */
   updateSFDCOpp(opportunity) {
    const isDistributor = opportunity?.Owner?.PartnerId ? true : false;
    let updatePayload: any = {
      Name: opportunity?.Name,
      Projected_Ship_Date__c: opportunity?.ShippingDate,
      Product__c: opportunity.PrimaryQuote.Product__c
    }
    let payload: any = {
      Name: opportunity?.Name,
      StageName: VwsSfdcOpportunityStage.DEFAULT,
      CloseDate: opportunity?.ShippingDate,
      LeadSource: SfdcLeadSource.CPQ,
      Projected_Ship_Date__c: opportunity?.ShippingDate,
      CPQ_Opportunity_Owner_Email__c: opportunity.Owner?.Email,
      Outside__c: isDistributor,
      CPQ_Job_Link__c: environment.B2CConfigs.redirectUrl + '/job/' + opportunity.Id
    }
    if(!opportunity.CrmId) {
      return this.salesforce.createOpportunity(payload).pipe(
        switchMap(res => {
          opportunity.CrmId = res.id;
          return this.cartService.updateObjectById(CpqObjects.Opportunity, opportunity.Id, {
            CrmId: res.id,
            Name: opportunity?.Name
          });
        }));
    } else {
      return this.salesforce.updateOpportunity(opportunity.CrmId, updatePayload);
    }
  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
