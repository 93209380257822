<div [formGroup]="categoryDetails" class="ml-2 mr-2 mt-3">
    <div class="search-box mt-2">
        <i class="fa fa-search"></i>
        <input class="form-control" type="text" name="search" placeholder="Filter Products" (keyup)="onSearchChange()"
            [pattern]="'^[a-zA-Z \-\']$'" formControlName="searchString" />
    </div>
</div>
<div class="flex-container" *ngFor="let category of filteredCategoriesWithProducts">
    <div class="cardAlignment d-flex  flex-wrap">
        <ngx-spinner class="spinner-attributes" [fullScreen]="false" type="cog" size="medium"
            [name]="ADD_PRODUCT_SPINNER">
            <p class="login-spinner mt-5">Please wait...</p>
        </ngx-spinner>
        <div *ngFor="let cardData of category.Products"><!--Fix Me: display only selected product-->
            <!-- <div class="card center-row vws-anchor" [ngClass]="{ disabled_card: !cardData.available }"
                (click)="cardData.available ? add(cardData) : false"> -->
            <div class="card center-row vws-anchor"
                (click)="add(cardData)">
                <div>
                    <i class="fa fa-plus align-add-icon"></i>
                    <div class="hrLine"></div>
                    <img class="card-img-top" [src]="getImageUrl(cardData)" [alt]="cardData.Label">
                </div>
                <div class="card-body">
                    <h5 class="card-text">{{cardData.Label}}</h5>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="filteredCategoriesWithProducts?.length < 1" class="mt-3 pl-2 no-match-min-h">
    <span class="sorry-no-matches">Sorry, no matches found for <b>{{this.categoryDetails?.value?.searchString}}</b>.
        Please
        try again.</span>
</div>