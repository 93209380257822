import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProductService, SaveDataRCresponse } from '@cpq-app/services/product.service';
import { Observable, throwError } from 'rxjs';
import { CdsAssemblyInfo } from './visualize.component';

const REQUEST_VARIABLES = {
    rc: 'rc',
    saveData: 'saveData',
    DeleteData: 'deleteData'
};

@Injectable({
    providedIn: 'root'
})
export class VisualizeService {
    constructor(
        private http: HttpClient,
        private productService: ProductService
    ) { }

    /**
     * Saves the cad assembly data as non-interactive cad data into RC blob.
     * @param cadAssemblyData - cad assmebly data
     * @param productId - Unique Id to store the cad data
     * @returns an id of the RC blob and saved cad data.
     */
    saveCadData(cadAssemblyData: CdsAssemblyInfo, productId: string): Observable<SaveDataRCresponse> {
        if (!Boolean(productId)) {
            return throwError('Valid Quoteline id is required');
        }
        const url = this.productService.cpqUrl(REQUEST_VARIABLES.rc, productId, REQUEST_VARIABLES.saveData);

        const modifiedData = JSON.parse(JSON.stringify(cadAssemblyData)) as CdsAssemblyInfo;

        for (const productName in modifiedData.products) {
            if ({}.hasOwnProperty.call(modifiedData.products, productName)) {
                modifiedData.products[productName].isInteractive = false;
            }
        }

        const payload = {
            data: JSON.stringify(modifiedData),
        };
        return this.http.post<SaveDataRCresponse>(url, payload, { withCredentials: true });
    }

    /**
     * Saves the cad assembly data as non-interactive cad data into RC blob.
     * @param cadAssemblyData - cad assmebly data
     * @param id - Unique Id to store the cad data
     * @returns an id of the RC blob and delete cad data.
     */
    deleteCadData(id: string): Observable<void> {
        if (!Boolean(id)) {
            return throwError('Valid Quoteline id is required');
        }
        const url = this.productService.cpqUrl(REQUEST_VARIABLES.rc, id, REQUEST_VARIABLES.DeleteData);
        return this.http.delete<void>(url, { withCredentials: true });
    }
}
