<div class="quote-details ml-2">
    <form class="quote-details-form" [formGroup]="revisionForm">
        <div class="quote-details-section">
            <div class="form-group required quote-name-field">
                <label for="Name" class="control-label required">Quote Name</label>
                <input validateOnBlur type="text" class="form-control" id="Name" formControlName="Name"
                    placeholder="Quote Name"
                    [ngClass]="{ 'is-invalid':  (revisionForm.controls['Name'].dirty || revisionForm.controls['Name'].touched || submitted) && revisionForm.controls['Name'].errors }">
                <div *ngIf="revisionForm.controls['Name'].invalid && (revisionForm.controls['Name'].dirty || revisionForm.controls['Name'].touched || submitted)"
                    class="invalid-feedback">
                    <div *ngIf="revisionForm.controls['Name'].errors.required">
                        Quote Name is required</div>
                    <div *ngIf="revisionForm.controls['Name'].errors.pattern">
                        Quote Name must be a character</div>
                    <div *ngIf="revisionForm.controls['Name'].errors.maxlength">Quote Name must contain max of
                        225
                        characters</div>
                </div>
            </div>
            <div class="form-group required quote-name-field" *hideFromCustomer>
                <label for="TotalAmount" class="control-label">Total Amount</label>
                <div class="input-group">
                    <input type="text" class="form-control" id="TotalAmount" formControlName="TotalAmount" readonly>
                </div>
            </div>
            <div class="tax-information" *hideFromCustomer>
                <div class="form-group child-control">
                    <label for="TaxRequired" class="control-label">Tax Required</label>
                    <div class="tax-radio" id="TaxRequired">
                        <label class="radio-inline">
                            <input class="mr-1" type="radio" name="TaxRequired__c" formControlName="TaxRequired__c" value="true">Yes
                        </label>
                        <label class="radio-inline">
                            <input   class="mr-1" type="radio" name="TaxRequired__c" formControlName="TaxRequired__c" value="false">No
                        </label>
                    </div>
                </div>
                <div class="form-group child-control" *ngIf="isTaxRequired()">

                    <label for="TaxAmount" class="control-label">Tax Amount($)</label>
                    <input type="number" class="form-control" id="TaxAmount" formControlName="TaxAmount__c"
                        [ngClass]="{'is-invalid':  (revisionForm.controls['TaxAmount__c'].dirty || revisionForm.controls['TaxAmount__c'].touched || submitted) && revisionForm.controls['TaxAmount__c'].errors }">
                    <div *ngIf="revisionForm.controls['TaxAmount__c'].errors" class="invalid-feedback">
                        <div *ngIf="revisionForm.controls['TaxAmount__c'].errors.min.actual < 0">Please enter positive
                            tax
                            amount.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="notes-section ml-4" *hideFromCustomer>
            <div class="note-details">
                <div class="form-group">
                    <label for="Note" class="control-label">Notes</label>
                    <textarea class="form-control note-w" id="note" rows="3" placeholder="Notes" formControlName="Note"
                        [ngClass]="{ 'is-invalid': submitted && revisionForm.controls['Note'].errors }"></textarea>
                    <div *ngIf="submitted && revisionForm.controls['Note'].errors" class="invalid-feedback">
                        <div *ngIf="revisionForm.controls['Note'].errors.maxlength">Note must contain max of
                            2000 characters
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>