<div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h2 class="modal-title col-md-6" id="newAccount"><strong>Request Help</strong></h2>
</div>
  <div class="modal-body">
    <div class="form-group">
        <textarea class="form-control vws-textarea" id="billingStreet" rows="6" placeholder="Note"></textarea>
      <!--   <div *ngIf="submitted && f.billingStreet.errors" class="invalid-feedback">
            <div *ngIf="f.billingStreet.errors.required">Billing Street is required</div>
        </div> -->
    </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="vws-primaryButton" (click)="onMessageSent()">Send Message</button>
      </div>