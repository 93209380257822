<div class="vws-container p-2">
    <div class="tunnel-parts-details">
        <div class="part-name-block mt-3">
            <span class="product-name" (click)="goBack()"><i class="fa fa-angle-left arrow"></i><span
                    class="m-2">{{currentSelectedOptionData?.Label}}</span></span>
            <ng-container *hideFromCustomer>
                <span *ngIf="!configurationChange" class="mr-2 product-price total-box ng-star-inserted">{{quotePrice
                    |currency}}</span>
                <ng-container *ngIf="configurationChange">
                    <span class="mr-2 product-price total-box ng-star-inserted">$###.##</span>
                    <span><i class='fa fa-info-circle ml-2 search-info' placement="bottom"
                            ngbTooltip="Configure your product and Save to see the 'price'."></i></span>
                </ng-container>
            </ng-container>

        </div>
        <div class="back-to-add-parts-block">
            <button class="back-to-add-parts" (click)="goBack()"><i class="fa fa-arrow-left"
                    aria-hidden="true"></i><span class="ml-2">Save Configuration</span></button>
        </div>
    </div>
    <div class="p-3">{{description}}</div>
    <hr class="hr">
</div>
<div id="cds-config-options" class="cds-config-options"></div>