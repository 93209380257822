import { Injectable } from '@angular/core';
import { LoginService } from '@cpq-app/services/login.service';
import { environment } from '@cpq-environments/environment';
import { MSALService } from './msal.service';

const CX_TOKEN = 'CXToken';
@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  constructor(
    private msalService: MSALService,
    private loginService: LoginService,
  ) { }

  logout() {
    this.loginService.logout().subscribe(
      res => {
        if(sessionStorage.getItem(CX_TOKEN)) {
          this.msalService.logout();
          window.location.href = `${environment.cxPortal.baseUrl}/logout`;
        } else {
          this.msalService.logout();
          window.location.href = `${environment.cxPortal.baseUrl}`;
        }
      },
      err => {
        this.msalService.logout();
        window.location.href = `${environment.cxPortal.baseUrl}`;
      });
  }

  logoutRedirectFromCXPortal() {
    this.loginService.logout().subscribe(
      res => {
        if(sessionStorage.getItem(CX_TOKEN)) {
          this.msalService.logout();
          window.location.href = `${environment.cxPortal.baseUrl}`;
        } else {
          this.msalService.logout();
          window.location.href = `${environment.cxPortal.baseUrl}`;
        }
      },
      err => {
        this.msalService.logout();
        window.location.href = `${environment.cxPortal.baseUrl}`;
      });
  }
}
