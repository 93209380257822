<div class="tunnel-top-banner">
    <div class="tunnel-base-info">
        <div class="tunnel-name">{{ aProductType }}</div>
        <div class="total-box" *hideFromCustomer><span class="small-text">Total <span class="excl-tax">(excl.
                    tax)</span></span> <span class="tunnel-name"
                *ngIf="!isConfigurationChange">{{totalAmount|currency}}</span>
            <ng-container *ngIf="isConfigurationChange">
                <span class=" tunnel-name">$###.##</span>
                <span><i class='fa fa-info-circle ml-2'
                        ngbTooltip="Configure your product and Save to see the 'price'."></i></span>
            </ng-container>

        </div>
    </div>
    <div class="quote-base-info">

        <div class="options">
            <div class="same-row-controls ">
                <label class="labelClass child-control">Building Length (foot)</label>
                <mat-form-field appearance="outline">
                    <input matInput type="number" [(ngModel)]="buildingLength" (blur)="onBuildingLengthChange()">
                </mat-form-field>
                <div class="options-element ml-3">
                    <label class="select-label">Power Supply (Voltage)</label>
                    <select class="form-control" [(ngModel)]="voltageRange" (change)="onVoltageChange()">
                        <option [value]="'208-230'">208-230</option>
                        <option [value]="460">460</option>
                        <option [value]="575">575</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-tab-container mt-2">
    <button type="button" class="tab-button btn-tab-next" (click)="goToPrevCategoryTab(currentCategoryTabIndex)"
        [disabled]="currentCategoryTabIndex === 0"><i class="fa fa-angle-left arrow"></i></button>
    <ul #categoryTabs class="nav nav-pills nav-tabs scroll" id="pills-tab" role="tablist">
        <li class="nav-item " *ngFor="let category of categories; let index = index;">
            <a class="nav-link" id="categoryId" data-toggle="pill" role="tab"
                (click)="onClickDisplayCategoriesParts(category,index)" aria-controls="category" aria-selected="true"
                [ngClass]="{'active': index === currentCategoryTabIndex}">{{category.Category}}</a>
        </li>
    </ul>
    <button type="button" class="tab-button btn-tab-prev" (click)="goToNextCategoryTab(currentCategoryTabIndex)"
        [disabled]="currentCategoryTabIndex === categories.length"><i class="fa fa-angle-right arrow"></i></button>
</div>

<div class="tunnel-configuration-block clearfix visualize-container">
    <div class="visualize-block" [hidden]="!is3dViewOn">
        <div class="mt-4 visualize-box">
            <div class="expand-icon-container" (click)="onExpandButtonClick()">
                <i class="fa window-control-icon" [ngClass]="{'fa-expand fa-expand-alt': !isExpandViewOn,
                  'fa-compress fa-compress-alt': isExpandViewOn}" aria-hidden="true"></i>
            </div>
            <div id="cdsRenderer" class="cds-cad-viewer-container row m-0 justify-content-center"></div>
            <div id="renderer-controls">
                <button class="action-buttons" (click)="resetView()">
                    <span class="ml-2">Reset View</span>
                </button>
                <button class="action-buttons" (click)="driveThroughTunnel()">
                    <span class="ml-2">Drive Through Tunnel</span>
                </button>
                <button class="action-buttons" (click)="viewAR()">
                    <span class="ml-2">View in Augmented Reality</span>
                </button>  
                <button id="close-qr" style="display: none" class="action-buttons" (click)="closeQR()">
                    <span class="ml-2">Close QR</span>
                </button>  
                <button class="action-buttons" (click)="share3D()">
                    <span class="ml-2">Share 3D</span>                    
                </button>                
            </div>
        </div>
    </div>
    <div class="tunnel-Parts-block" [hidden]="is3dExpandViewOn && is3dViewOn"
        [ngClass]="{'col-md-4 config-section':is3dViewOn, 'col-md-12':!is3dViewOn}">
        <div class="mt-3" *ngIf="showCancel">            
            <button class="add-button return-button"
                (click)="showCancel = !showCancel ; showAddProductsDetails = !showAddProductsDetails">
                <span class="ml-2">Cancel Add Part</span>
            </button>
        </div>
        <div *ngIf="!showTunnelProductDetails && !showAddProductsDetails">
            <div>
                <div class="mt-3" *ngIf="addedQuoteLines.length>0">
                    <button class="add-button return-button" (click)="openAddParts(selectedCategory, $event)">
                        <i class="fa fa-plus align-add-icon"></i><span class="ml-2">Add to Cart</span>
                    </button>
                </div>
                <div class="parts-block">
                    <ngx-spinner class="spinner-attributes" [fullScreen]="false" type="cog" size="medium"
                        [name]="PART_SPINNER">
                        <p class="login-spinner mt-5">Please wait...</p>
                    </ngx-spinner>
                    <div class="child part-card mt-1" *ngFor="let part of addedQuoteLines;let quoteLineIndex = index">
                        <div class="d-flex justify-content-between flex-nowrap  pt-3 ml-3">
                            <div class="card-label">
                                <a (click)="onClickOfAddedParts(part)">                                   
                                    <span class="title"><strong>{{part?.Name}}</strong>
                                        <div class="title-options" placement="bottom-left"
                                            [ngbTooltip]="part?.OptionsSummary">
                                            {{ part?.OptionsSummary |
                                            truncate:shortSummeryLength }}
                                        </div>
                                    </span></a>
                            </div>
                            <div class="part-price alignRight mt-2" *hideFromCustomer>
                                <span>{{part.TotalSellingPrice | currency}}</span>
                            </div>
                            <div class="action-icons d-flex mt-2">
                                <span><a (click)="deleteNode(part)"><i class="fa fa-trash font-16 iconcolor"
                                            title="Delete Part"></i></a></span>
                                <span *ngIf = "selectedCategory?.CategoryId !== conveyerCategory "><a (click)="copyNode(part)"><i class="fa fa-copy font-16 iconcolor" title="Duplicate Part">
                                </i></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-tunnel-product-details *ngIf="showTunnelProductDetails && !showAddProductsDetails"
            [selectedOptionData]="selectedOptionData" [currentCategoryTabIndex]="currentCategoryTabIndex"
            (goBackToTunnelConfig)="loadTunnelConfig()" [configuratorData]="configuratorData"
            [configurationChange]="isConfigurationChange" [quoteLineId]="quoteLineId"
            [quotePrice]="selectedQuotePrice" [description]="productDescription"></app-tunnel-product-details>
        <div id="cds-config-options" class="cds-config-options insideContainer p-2"
            [ngStyle]="{'display': (showTunnelProductDetails && !showAddProductsDetails) ? 'block' : 'none'}"></div>
        <app-add-products *ngIf="showAddProductsDetails  && !loadingData" [mainGroup]="mainGroup" [productType] = "aProduct"
            (selectedProductData)="openPart($event)"></app-add-products>
    </div>
</div>