<nav class="navbar second-header body-section navbar-expand-sm justify-content-between">
  <div><a class="navbar-brand mt-1" [routerLink]="['/']">
      <img [src]="opcoLogoUrl" alt="logo" class="opcoLogo" />
    </a></div>
  <ul class="navbar-nav ml-auto mt-1">
    <li class="nav-item">
      <a class="" [routerLink]="['/']">
        <div class="d-flex flex-column">
          <i class="fa fa-wrench fa_Configure fa-5x"></i>
          <span class="configure">CONFIGURE</span>
        </div>
      </a>
    </li>
  </ul>
</nav>