import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { AppComponent } from './app.component';
import { MSALService } from './shared/services/msal.service';
import { AuthenticationHttpInterceptor } from './shared/services/authentication.httpInterceptor';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StaticjsonfileService } from './shared/services/staticjsonfile.service';
import { AuthGuard } from './shared/services/authguard.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { OrderModule } from 'ngx-order-pipe';
import { ExcelService } from './shared/services/excel.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EntityImageService } from './shared/services/entity-image.service';
import { BlobService } from './shared/services/blob.service';
import { EntityService } from './shared/services/entity.service';
import { AlertService } from './shared/services/alert.service';
import { AppRoutingModule } from './app-routing.module';
import { PagerService } from './shared/services/pager.service';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AlertComponent } from './shared/directives/alert.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CsvService } from './shared/services/csv.service';
import { MaterialModule } from '../app/shared/material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TWGModule } from '@twg-app/TWG.module';
import { VWSModule } from '@vws-app/VWS.module';
import { AppPathResolve } from 'src/app/resolver/appPathResolve';
import { CartService } from '../app/services/cart.service';
import { LoaderService } from '../app/services/loader.service';
import { LoaderComponent } from './shared/loader/loader.component';

import { BreadcrumbsModule } from './shared/breadcrumbs/breadcrumbs.module';

import { TopHeaderComponent } from './header/top-header/top-header.component';
import { SecondHeaderComponent } from './header/second-header/second-header.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SubmitQuoteModelComponent } from './content/submit-quote-model/submit-quote-model.component';
import { SalesforceProxyService } from './services/salesforce.service';
import { ThankYouModalComponent } from './content/thank-you-modal/thank-you-modal.component';
import { LoginService } from './services/login.service';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordSuccessComponent } from './changePasswordSuccess/changePasswordSuccess.component';
import { LandingTileDataService } from './shared/services/Landing-tile-data.service';
import { DataTablesModule } from 'angular-datatables';
import { FooterSectionComponent } from './tenants/VWS/footer-section/footer-section.component';
import { HeaderSectionComponent } from './tenants/VWS/header-section/header-section.component';
import { HeaderTopComponent } from './tenants/VWS/header-section/header-top/header-top.component';
import { HeaderMiddleComponent } from './tenants/VWS/header-section/header-middle/header-middle.component';
import { HeaderBottomComponent } from './tenants/VWS/header-section/header-bottom/header-bottom.component';
import { LogoutComponent } from './logout/logout.component';
import { SharedModule } from './shared/shared-module';
import { SharedService } from './shared/services/shared.service';
import { CxCpqSsoComponent } from './cx-cpq-sso/cx-cpq-sso.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PagenotfoundComponent,
    TopHeaderComponent,
    SecondHeaderComponent,
    LoaderComponent,
    AlertComponent,
    SubmitQuoteModelComponent,
    ThankYouModalComponent,
    PrivacyTermsComponent,
    ProfileComponent,
    ChangePasswordSuccessComponent,
    FooterSectionComponent,
    HeaderSectionComponent,
    HeaderTopComponent,
    HeaderMiddleComponent,
    HeaderBottomComponent,
    LogoutComponent,
    CxCpqSsoComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    OrderModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    SelectDropDownModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      timeOut: 2000
    }),
    NgbModule,
    NgIdleModule.forRoot(),
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ColorPickerModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    BreadcrumbsModule,
    TWGModule,
    VWSModule,
    DataTablesModule,
    SharedModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MSALService,
    ExcelService,
    AuthGuard,
    PagerService,
    EntityImageService,
    StaticjsonfileService,
    AlertService,
    BlobService,
    EntityService,
    CsvService,
    AppPathResolve,
    CartService,
    LoaderService,
    SalesforceProxyService,
    LoginService,
    LandingTileDataService,
    SharedService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationHttpInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'USD'
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

