import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Variables } from '@cpq-app/_administration/common.types';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService, CpqObjects, CpqObjectType, JOB_STATUS } from '@cpq-app/services/cart.service';
import { LoginService } from '@cpq-app/services/login.service';
import { ProductService } from '@cpq-app/services/product.service';
import { ButtonClass, ButtonTitle, CursorCssClass, DatatableColumn, DataTableEvent, Icons, PageHeaderInformation, PageName, RowActions as TableRowActions } from '@cpq-app/shared/datatable/datatable';
import { TruncatePipe } from '@cpq-app/shared/pipes/truncate.pipe';
import { CpqJob } from '@cpq-app/tenants/Cpq.interfaces.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CURRENT_RECORD_STATE } from '@vws-app/configuration/configuration.component';
import { CpqOperations } from '@vws-app/cpqOperations.service';
import { RenewQuoteModalObject } from '@vws-app/revision/renew-quote/renew-quote.component';
import { LibraryOpportunity, VwsCpqQuote } from '@vws-app/VWS.interfaces.service';
import { differenceInCalendarDays } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SubmitJobComponent } from '../submit-job/submit-job.component';

const DATE_FMT = 'yyyy-MM-dd';

const POST_EXPIRED = 'The pricing on your quote has expired and will now be updated.';
const EXPIRE_TODAY = 'The primary quote will expire today';
const EXPIRE_IN_DAYS = (days: number) => `The primary quote will expire within ${days > 1 ? `${days} days` : `${days} day`}`;
const DAYS_TO_BEGIN_EXPIRY_WARNING = 3;

enum dataTableButtons {
  newQuote = 'new_quote',
  placeOrder = 'place_order'
}

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss'],
  providers: [CurrencyPipe, TruncatePipe]
})
export class RevisionComponent implements OnInit, OnDestroy {
  revisions: any[] = [];
  jobID: string;
  customerViewSubscription: Subscription;
  loggedInSubscription: Subscription;
  colData$: BehaviorSubject<DatatableColumn<VwsCpqQuote>[]>;
  dataLoading = false;
  subscription$: Subscription[] = [];
  opportunityData: any = [];
  libraryOpportunity = false;
  @Input() isOrderPlaced;
  pageHeaderInformation: PageHeaderInformation = {
    title: PageName.quotes,
    buttons: [{
      field: dataTableButtons.newQuote,
      title: ButtonTitle.quote,
      icon: Icons.plus,
      className: ButtonClass.white,
      disable: () => (Boolean(this.isOrderPlaced) )
    },
    {
      field: dataTableButtons.placeOrder,
      title: ButtonTitle.order,
      className: ButtonClass.grey,
      disable: () => (!Boolean(this.revisions?.length) || Boolean(this.isOrderPlaced)),
    }
    ]
  }

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private userService: UsersService,
    private vwsCpqOperations: CpqOperations,
    private productService: ProductService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) {
    this.route.params.subscribe({
      next: (params) => {
        if (params.jobID) {
          this.jobID = params.jobID;
          this.init();
        }
      }
    });
    this.cartService.placeOrderOnRevision$.subscribe({
      next: () => this.getRevisionData()
    });
  }

  buttonClick(field) {
    if (field === dataTableButtons.newQuote) {
      this.router.navigate([this.jobID, 'choose']);
    } else {
      this.spinner.show();
      const instance = this.modalService.open(SubmitJobComponent, {
        size: 'lg'

      }).componentInstance;
      instance.jobID = this.jobID;
      this.activeModal.close();
    }
    
  }
  ngOnInit(): void {
  }

  init() {
    this.spinner.show();
    this.getRevisionData();

    const colData = this.getColData();
    this.colData$ = new BehaviorSubject<DatatableColumn<VwsCpqQuote>[]>(colData);
    this.subscribeToCustomerView();
  }

  getColData(customerView = false): DatatableColumn<VwsCpqQuote>[] {
    const colData: DatatableColumn<VwsCpqQuote>[] = [
      {
        field: 'Name', header: 'Quote Name', isPrimary: true,
        visibility: true, sortable: true, filterable: true,
        value: (record: VwsCpqQuote) => record?.Name, handler: (record: VwsCpqQuote) => this.onClickEdit(record),
        className: (record: VwsCpqQuote) => this.passEditClass(record),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => a?.Name?.localeCompare(b?.Name),
      },
      {
        field: 'IsPrimary', control: 'checkbox', header: 'Preferred',
        visibility: true, sortable: true,
        value: (record: VwsCpqQuote) => record.IsPrimary,
        handler: (record) => this.onClickPrimaryCheck(record),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => Number(a.IsPrimary > b.IsPrimary) - Number(a.IsPrimary < b.IsPrimary)
      },
      {
        field: 'ExpirationTime', header: 'Expiration Date',
        visibility: true, sortable: true,
        value: (record: VwsCpqQuote) => this.datePipe.transform(record.ExpirationTime, DATE_FMT),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => {
          const aD = new Date(a.ExpirationTime);
          const bD = new Date(b.ExpirationTime);
          return Number(aD > bD) - Number(aD < bD);
        }
      },
      {
        field: 'TotalAmount', header: 'Total Amount',
        visibility: !customerView, sortable: true,
        value: (record: VwsCpqQuote) => this.currencyPipe.transform(record.TotalAmount),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => { return (Number(a.TotalAmount) - Number(b.TotalAmount)) },
      },
      {
        field: 'ModifiedTime', header: 'Last Modified',
        initialSort: true, sortable: true, visibility: true,
        value: (record: VwsCpqQuote) => this.datePipe.transform(record.ModifiedTime, DATE_FMT),
        compare: (a: VwsCpqQuote, b: VwsCpqQuote) => {
          const aD = new Date(a.ModifiedTime);
          const bD = new Date(b.ModifiedTime);
          return Number(aD > bD) - Number(aD < bD);
        }
      },
      {
        field: 'actions', header: 'Actions',
        visibility: true,
        disableActionProperty: TableRowActions.DELETE,
        disable: (record: VwsCpqQuote) => (record.IsPrimary === 1),
        value: (quote) => {
          const actionsArray: DataTableEvent[] = [];
          const role = sessionStorage.getItem('userRole');
          this.libraryOpportunity = this.jobID === LibraryOpportunity;
          if (role === Variables.ADMIN && this.jobID !== LibraryOpportunity) {
            actionsArray.push({ action: TableRowActions.Fav, tooltip: 'add to pre-configured products.', handler: () => this.onFavClick(quote) });
          }
          // if opportunity is not submitted then only show copy and edit option
          if (this.cartService.getVWSOpportunityStatus(this.opportunityData) !== JOB_STATUS.SUBMITTED) {
            actionsArray.push({ action: TableRowActions.EDIT, handler: () => this.onClickEdit(quote) },
              { action: TableRowActions.COPY, handler: () => this.onClickCopy(quote) });
          }
          if (quote.TotalAmount !== 0 && !customerView) {
            actionsArray.push({ action: TableRowActions.DOWNLOAD, handler: () => this.onClickDownload(quote) });
          }
          actionsArray.push({ action: TableRowActions.DELETE, handler: () => this.openDeleteModel(quote) });
          return actionsArray;
        }
      }
    ];
    return colData;
  }

  getRevisionData() {
    this.dataLoading = true;
    this.revisions = [];
    this.opportunityData = []
    //   CpqQueryObjects.OpportunityQuotes, { Id: this.jobID, sort: '-LastModifiedDate', resolveNames: 'true' })
    this.subscription$.push(this.cartService.getCpqObjectByIdCDS<CpqJob>(
      CpqObjects.Opportunity, this.jobID)
      .subscribe({
        next: (opportunity: CpqJob) => {
          this.opportunityData = opportunity;
          if (this.opportunityData.CrmExportStatus === JOB_STATUS.COMPLETED) {
            this.isOrderPlaced = true;
            this.cartService.orderPlaced$.next(true);
          }
          this.spinner.hide();
          this.revisions = [...this.revisions, ...opportunity?.Quotes];
          this.revisions = this.revisions.sort((a, b) => {
            const aD = new Date(a.ModifiedTime);
            const bD = new Date(b.ModifiedTime);
            return Number(aD < bD) - Number(aD > bD);
          });
          this.conditionallyDisplayExpiryWarning(this.revisions);
        },
        complete: () => {
          this.dataLoading = false;
        },
        error: (err) => {
          this.spinner.hide();
          this.toastr.error(
            'There is fatal error while fetching revision', 'Error', {
            disableTimeOut: true,
            closeButton: true
          }
          );
        }
      }));
  }

  onFavClick(quote: VwsCpqQuote) {
    this.openConfirmationModel(quote)
  }

  /**
 * @param id - pass quote ID to update the products information
 */
  openConfirmationModel(quote: VwsCpqQuote) {
    const message = `Do you really want to add '${quote?.Name}' to the pre-configured products?`;
    const data = {
      message,
      recordID: quote.Id,
    };
    const instance = this.vwsCpqOperations.openDeleteModelDialog(data);
    instance.result.then((outcome) => {
      if (outcome === quote.Id) {
        const payload = { Image__c: this.productService.getImageName(quote?.Product__c) }
        this.spinner.show();
        this.cartService.addToPreconfiguredQuotes(quote.Id, payload).subscribe({
          next: (quote: any) => {
            this.spinner.hide();
            this.toastr.success('Successfully added to the library quote')
          },
          error: err => {
            this.spinner.hide();
          }
        })
      }
    })
  }

  onClickEdit(quote: VwsCpqQuote) {
    // TODO: check for "libraryOpportunity" flag
    // for libraryOpportunity call - PUT /projects/templates/<>
    this.spinner.show();
    const daysUntilExpired = this.calculateDaysUntilExpired(quote);
    if (daysUntilExpired < 0) {
      this.openWarningModel(quote.Id, true, POST_EXPIRED);
      this.spinner.hide();
      return;
    }
    sessionStorage.setItem(CURRENT_RECORD_STATE.RECORD_STATE, CURRENT_RECORD_STATE.EDIT_VIEW);
    this.navigateToConfigPage(quote);
  }

  passEditClass(quote: VwsCpqQuote): string {
    if (this.cartService.getVWSOpportunityStatus(this.opportunityData) === JOB_STATUS.SUBMITTED) {
      return CursorCssClass.DISABLE_POINTER;
    }
    return CursorCssClass.ALLOW_CLICK;
  }

  navigateToChoosePage(quote: VwsCpqQuote) {
    try {
      this.router.navigate([this.jobID, 'choose']);
    } catch (error) {
      this.spinner.hide();
      console.log(error);
    }
  }

  navigateToConfigPage(quote: VwsCpqQuote) {
    //this.navigateToChoosePage(quote);
    this.cartService.getCpqObjectByIdCDS<any>(CpqObjects.Quote, quote.Id).subscribe({
      next: (quoteWithProducts: any) => {
        console.log('quoteWithProducts', quoteWithProducts)
        if (quoteWithProducts.Lines.length > 0) {
          this.reopenConfiguration(quoteWithProducts);
        } else {
          this.navigateToChoosePage(quote);
        }
      },
      error: err => {
        this.spinner.hide();
        this.toastr.error('Failed to fetch data. Please contact your CPQ administrator', 'Error!', {
          disableTimeOut: true,
          closeButton: true
        });
      }
    })
    // this.vwsCpqOperations.getQuoteLineProducts(quote.Id).subscribe({
    //   next: (quoteWithProducts: any) => {
    //     console.log('quoteWithProducts', quoteWithProducts)
    //     if (quoteWithProducts.QuoteLines.length > 0) {
    //       this.reopenConfiguration(quoteWithProducts);
    //     } else {
    //       this.navigateToChoosePage(quote);
    //     }
    //   },
    //   error: err => {
    //     this.spinner.hide();
    //     this.toastr.error('Failed to fetch data. Please contact your CPQ administrator', 'Error!', {
    //       disableTimeOut: true,
    //       closeButton: true
    //     });
    //   }
    // });
  }

  reopenConfiguration(quote: VwsCpqQuote) {
    //TODO: check navigation
    const [quoteLine] = quote.Lines;
    // const productId = (quoteLine?.ProductId as any)?.Id;
    const productId = quoteLine?.Id;
    if (!Boolean(productId)) { return; }
    this.navigateToConfig(quote, productId);
    // this.cartService.reopenConfiguration(productId).subscribe({
    //   next: (configSession) => {
    //     if (!configSession.configId) {
    //       this.spinner.hide();
    //       this.toastr.error('A valid configuration ID is missing. Please contact CPQ administrator.', 'Error!', {
    //         disableTimeOut: true,
    //         closeButton: true
    //       });
    //     } else {
    //       this.navigateToConfig(quote, configSession.configId, productId);
    //     }
    //   },
    //   error: err => {
    //     this.spinner.hide();
    //   }
    // });
  }

  navigateToConfig(quote: VwsCpqQuote, productID: string): void {
    this.router.navigate([this.jobID, quote.Id, 'choose', 'configuration'],
      { queryParams: { update: CpqObjectType.Product, productID } }
    );
  }

  calculateDaysUntilExpired(quote: VwsCpqQuote): number {
    if (quote?.ExpirationTime) {
      return differenceInCalendarDays(new Date(quote.ExpirationTime), new Date());
    }
    return undefined;
  }

  conditionallyDisplayExpiryWarning(revisions: VwsCpqQuote[]) {
    revisions
      .filter(quote => quote.IsPrimary)
      .slice(0, 1)
      .forEach(primaryQuote => {
        const days = this.calculateDaysUntilExpired(primaryQuote);
        if (days >= 0 && days <= DAYS_TO_BEGIN_EXPIRY_WARNING) {
          const message = (days < 1) ? EXPIRE_TODAY : EXPIRE_IN_DAYS(days);
          this.openWarningModel(primaryQuote.Id, false, message);
        }
      });
  }

  openWarningModel(quoteId: string, isRecordExpired: boolean, message: string) {
    const data: RenewQuoteModalObject = {
      message,
      recordID: quoteId,
      isRecordExpired
    };
    const instance = this.vwsCpqOperations.openModelDialog(data);
    instance.result.then(
      (outcome) => {
        if (outcome === quoteId) {
          const [quote] = this.revisions.filter(revision => revision.Id === quoteId);
          this.navigateToConfigPage(quote);
        }
      },
      (dismiss) => {
        console.log(dismiss);
      });
  }

  navigateToEditPage(quote: VwsCpqQuote) {
    try {
      this.router.navigate([this.jobID, quote.Id, 'choose']);
    } catch (error) {
      this.spinner.hide();
      console.log(error);
    }
  }

  onClickCopy(sourceQuote: VwsCpqQuote) {
    this.copyNewRecord(sourceQuote);
    const payload = {
      Name: `Copy of ${sourceQuote?.Name}`
    }
    this.cartService.copyObjectById(CpqObjects.Quote, sourceQuote?.Id, payload).subscribe({
      next: (results) => {
        this.revisions.forEach(quote => {
          if (quote.Id === null) {
            quote.Id = results.Id;
          }
        });
        this.getRevisionData();
      },
      error: (err) => {
        this.toastr.error(
          'There was an error while copying revisions', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  copyNewRecord(sourceQuote: VwsCpqQuote) {
    const newQuote = Object.assign({}, sourceQuote);
    const date = new Date();
    const ISOFormate = date.toISOString().split('.')[0] + 'Z';
    newQuote.Id = null;
    newQuote.LastModifiedDate = ISOFormate;
    newQuote.CreatedDate = ISOFormate;
    newQuote.IsPrimary = 0;
    this.revisions.push(newQuote);
  }

  /**
 * @param id - pass quote ID to update the products information
 */
  openDeleteModel(quote: VwsCpqQuote) {
    const message = `Do you really want to delete quote '${quote?.Name}'?`;
    const data = {
      message,
      recordID: quote.Id,
    };
    const instance = this.vwsCpqOperations.openDeleteModelDialog(data);
    instance.result.then((outcome) => {
      if (outcome === quote.Id) {
        this.onClickDelete(quote)
      }
    })
  }

  onClickDelete(quote: VwsCpqQuote) {
    this.revisions = this.revisions.filter(item => (item.Id.indexOf(quote.Id) === -1));
    if (this.revisions.length) {
      this.revisions[0].IsPrimary = 1;
    }
    this.cartService.deleteObjectByObjectId(CpqObjects.Quote, quote.Id).subscribe({
      next: () => {
        this.getRevisionData();
      },
      error: (err) => {
        this.toastr.error(
          'There is fatal error while deleting revisions', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  onClickDownload(record: VwsCpqQuote) {
    this.productService.quoteProposalUrl(record.Id);
    // this.productService.exportTable();
    //this.productService.exportEmailTemplate();
  }

  /**
   * Change the Primary Quote/Revision immediately and post change to backend
   * @param targetQuote
   */
  onClickPrimaryCheck(targetQuote: VwsCpqQuote): void {
    // Confirm a change is required
    if (targetQuote.IsPrimary) {
      return;
    }

    // Deselect any other primary quotes, then set the new one as primary
    this.revisions.forEach(quote => quote.IsPrimary = false);
    targetQuote.IsPrimary = 1;

    // Send change to backend, then refresh data
    const obj = {
      PrimaryQuoteId: targetQuote.Id
    };
    this.cartService.updateObjectById(CpqObjects.Opportunity, this.jobID, obj).subscribe({
      next: (id) => {
        this.getRevisionData();
      },
      error: (err) => {
        console.log(err);
        this.getRevisionData();
        this.toastr.error(
          'There was a fatal error while changing the Primary Revision, please try again', 'Error', {
          disableTimeOut: true,
          closeButton: true
        }
        );
      }
    });
  }

  subscribeToCustomerView() {
    this.customerViewSubscription = this.userService.getCustomerView()
      .subscribe({
        next: isActive => {
          const colData = this.getColData(isActive);
          this.colData$.next(colData);
        },
        complete: () => this.subscribeToCustomerView(),
      });
  }

  ngOnDestroy() {
    this.customerViewSubscription?.unsubscribe();
    this.loggedInSubscription?.unsubscribe();
    this.subscription$.forEach(sub => sub.unsubscribe());
  }

}
