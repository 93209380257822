import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { MSALService } from 'src/app/shared/services/msal.service';
import { LogoutService } from '@cpq-app/shared/services/logoutService';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { environment } from '@cpq-environments/environment';
import { HeaderService } from '@cpq-app/header/header.service';
import { SessionVariables } from '../header-section.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TutorialsComponent } from '../../help-support/tutorials/tutorials.component';
import { FAQsComponent } from '../../help-support/faqs/faqs.component';
import { LibraryOpportunity, VWSInterfacesService } from '../../VWS.interfaces.service';
import { Variables } from '@cpq-app/_administration/common.types';
import { LoginService } from '@cpq-app/services/login.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
})
export class HeaderTopComponent implements OnChanges, OnInit {
  @Input() data;
  @Input() userNameData;
  userName = '';
  personaName = '';
  isCustomerView = false;
  is3DViewOn = true;
  userType = '';
  isAdmin=false
  headerLogoUrl = environment.cxPortal.baseUrl;

  constructor(
    private router: Router,
    private authService: MSALService,
    private logoutService: LogoutService,
    private loginService: LoginService,
    private toastr: ToastrService,
    private userService: UsersService,
    private headerService: HeaderService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.loginService.userRole.subscribe(role => {
      this.isAdmin = role === Variables.ADMIN;
    })
    this.headerService.getUserName().subscribe((name) => {
      this.userName = sessionStorage.getItem(SessionVariables.USER_TYPE) ? name + `(${sessionStorage.getItem(SessionVariables.USER_TYPE)})` : name;
    });
    this.subscribeToCustomerView();
    this.subscribeTo3DView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.data?.firstChange) {
      if (!changes.data?.currentValue?.user) {
        this.userName = 'Admin';
        this.personaName = 'Super Admin';
      } else {
        this.personaName = changes.data?.currentValue?.user?.role;
      }
      this.userType = changes.data?.currentValue?.user?.userType;
    }
    if (!changes.userNameData?.firstChange) {
      this.userName = changes.userNameData.currentValue;
    }
  }

  logout() {
    this.logoutService.logout();
  }

  redirectToCxportal() {
    window.location.href = environment.cxPortal.baseUrl;
  }

  openUserProfile() {
    if (this.userType !== 'superadmin') {
      this.router.navigate(['/profile']);
    } else {
      this.toastr.info('Profile is not available for super admin.');
    }
  }

  openDistributorProfile() {
    this.router.navigate(['/_admin/organizations/profile']);
  }

  changePassword() {
    this.authService.callChangePasswordPolicy();
  }

  openTutorial() {
    const instance = this.modalService.open(TutorialsComponent, {
      size: 'xl'
    });
    instance.result.then((outcome) => { }, (dismiss) => {
      // gets triggers when modal is dismissed.
    });
  }

  openFAQ() {
    const instance = this.modalService.open(FAQsComponent, {
      size: 'xl'
    });
    instance.result.then((outcome) => { }, (dismiss) => {
      // gets triggers when modal is dismissed.
    });
  }


  toggleCustomerViewMode(chkEvent: Event) {
    const checkbox = chkEvent.target as HTMLInputElement;
    this.userService.setCustomerViewStatus(checkbox.checked || false);
  }


  toggle3DViewMode(chkEvent: Event) {
    const checkbox = chkEvent.target as HTMLInputElement;
    this.userService.set3DViewStatus(checkbox.checked || false);
  }

  subscribeToCustomerView() {
    this.userService.getCustomerView()
      .subscribe({
        next: isActive => this.isCustomerView = isActive,
        complete: () => this.subscribeToCustomerView(),
      });
  }

  subscribeTo3DView() {
    this.userService.get3DView()
      .subscribe({
        next: isActive => this.is3DViewOn = isActive,
        complete: () => this.subscribeTo3DView(),
      });
  }
  navigateToAccountsPage() {
    this.router.navigate(['accounts']);
  }
  navigateToConfiguredQuotes() {
    // TODO: Navigate to quote listing page
    this.router.navigate(['/manageLibraryQuotes']);
  }
  navigateToHome() {
    this.router.navigate(['/']);
  }
}
