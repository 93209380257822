<div class="second-header" id="mainSecondHeader">
    <nav class="navbar navbar-expand-sm second-header" id="navbar-second-header">
        <div class="container" id="containerSecondHeader">
            <ul class="nav navbar-nav ml-auto mt-1 header-menu-label">
                <li class="nav-item mr-2">
                    <a class="nav-link" (click)='navigateToDashboardPage()'>
                        <div class="d-flex flex-column">
                            DASHBOARD
                        </div>
                    </a>
                </li>
                <!-- <li class="nav-item mr-2" [ngClass]="{'active': configIsActive()}">
                    <a class="nav-link" (click)='navigateToConfigPage()'>
                        <div class="d-flex flex-column">
                            CONFIGURATION
                        </div>
                    </a>
                </li> -->
                <li class="nav-item mr-2" [ngClass]="{'active': accountsIsActive()}">
                    <a class="nav-link" (click)='navigateToAccountsPage()'>
                        <div class="d-flex flex-column">
                            Accounts
                        </div>
                    </a>
                </li>
                <li class="nav-item dropdown ml-3">
                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">HELP
                        <i class="arrow down"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <button class="dropdown-item" (click)="openTutorial()">Tutorials</button>
                        <button class="dropdown-item" (click)="openFAQ()">FAQs</button>
                    </div>
                </li>
                <li class="nav-item dropdown ml-3">
                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">SETTINGS
                        <i class="arrow down"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <button (click)="$event.stopPropagation()" class="dropdown-item">Customer View <i
                                class="fa fa-info-circle info" title="This is to enable Customer View"></i>
                            <label class="switch">
                                <input type="checkbox" [checked]="isCustomerView"
                                    (change)="toggleCustomerViewMode($event)">
                                <div class="slider round">
                                    <span class="on">ON</span>
                                    <span class="off">OFF</span>
                                </div>
                            </label>
                        </button>
                        <button (click)="$event.stopPropagation()" class="dropdown-item">3D View <i
                                class="fa fa-info-circle info" title="This is to hide 3D View"></i>
                            <label class="switch">
                                <input type="checkbox" [checked]="is3DViewOn" (change)="toggle3DViewMode($event)">
                                <div class="slider round">
                                    <span class="on">ON</span>
                                    <span class="off">OFF</span>
                                </div>
                            </label>
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</div>