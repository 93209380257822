<div class="modal-header d-flex justify-content-between share3D-header">
    <div class="header">Share 3D </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body mb-2 mt-1">
    <span class="label-share mb-1">Shareable 3d link</span>
    <input type="text" class="share3dLinkBox" [value]="generate3DLink()" />

</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <span class="label-share m-3">3D link(Activate/Deactivate)</span>
        <button (click)="$event.stopPropagation()" class="deactivate-button">
            <label class="switch">
                <input type="checkbox" [checked]="isShare3DLinkActive" (change)="toggle3DShareMode($event)">
                <div class="slider round">
                    <span class="on">ON</span>
                    <span class="off">OFF</span>
                </div>
            </label>
        </button>
    </div>
    <button type="button" class="copy-link-button mr-3" (click)="copy3dLink()">Copy Link</button>
</div>