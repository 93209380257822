<footer [ngClass]="">
    <div class="container-theme1">
        <div class="left-section">
            <h4 class="opco-name">
                <a href="https://www.opwglobal.com/belanger" target="_blank">BELANGER, INC.</a>
            </h4>
            <p class="address-p">
                1001 Doheny Court
                <br>P.O. Box 5470
                <br>Northville, MI 48167
                <br>Tel: (866) 483-2867 – Tel: (248) 349-7010
                <br>
            </p>
        </div>
        <div class="right-section responsive-float-fix">
            <ul class="nav-links">
                <li><a href="https://www.opwglobal.com/belanger/company/contact-us/" target="_blank">CONTACT US</a></li>
                <li><a href="https://www.opwglobal.com/privacy-policy" target="_blank">PRIVACY POLICY</a></li>
                <li><a href="assets/documents/GENERAL TERMS AND CONDITIONS OF SALE REVISED 6.3.20.pdf"
                        target="_blank">TERMS & CONDITIONS</a></li>
            </ul>
        </div>
    </div>
</footer>