export class VwsErrorMessages {
    static GENERIC = 'There was an unexpected error; please try again';
    static CREATING_CONFIG = 'There was a fatal error while creating your configuration';
    static OPENING_CONFIG = 'There was a fatal error while opening your configuration';
    static REQUIREDFIELD = 'Please fill the required fields';
    static MINFIELD = 'Please enter positive tax amount.';
}

export enum RouteNames {
    FAMILY = 'Select Model',
    RESULTS = 'Select Product',
    CONFIGURATION = 'Configure',
    REVISION_VIEW = 'Save',
    ACCOUNTS = 'accounts'
}
