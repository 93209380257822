import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-second-header',
  templateUrl: './second-header.component.html',
  styleUrls: ['./second-header.component.scss'],
})
export class SecondHeaderComponent implements OnInit {
  opcoDataServiceSubscription: Subscription;
  headerFooterUrls: any = {};
  isOpcoDataLoading = true;
  opcoLogoUrl = 'assets/images/logo-twg.png';
  
  constructor() { }
  ngOnInit() {}
}
