// This file can be replaced during build by deployment script.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.This setting has been removed due to security purpose.
// The list of file replacements can be found in `deploy.ps1`.
// import { InjectionToken } from '@angular/core';
export const environment = {
  production: true,
  version: "0.0.0.0",
  B2CConfigs: {
    BackendURL: 'https://vws.cdsvisual.net/api',
    BlobStorageURL: 'http://entitystorage.blob.core.windows.net',
    DefaultBlobId: 'private',
    ImageBackendUrl: 'https://vws.cdsvisual.net/api',
    b2cScopes:
      'https://cxportalprod.onmicrosoft.com/e73d5bbf-e9e0-4358-8468-3cd39b9a64a6/user.read',
    clientId: 'e73d5bbf-e9e0-4358-8468-3cd39b9a64a6',
    isPolicy: true,
    redirectUrl: 'https://vws.cdsvisual.net',
    superAdminOid: 'a24129ff-9321-495f-8bef-b35325ec88f5',
    tenant: 'cxportalprod.onmicrosoft.com',
    authURL: "https://cxportalprod.b2clogin.com/tfp",
    signinPolicy: {
      TWG: 'b2c_1a_cxportal_prod_signin_policy_vws',
      VWS: 'b2c_1a_cxportal_prod_signin_policy_vws',
    },
    resetPasswordPolicy: {
      TWG: 'B2C_1A_PasswordResetTWG',
      VWS: 'B2C_1A_PasswordResetVWS',
    },
    changePasswordPolicy: {
      TWG: 'B2C_1A_PasswordChangeTWG',
      VWS: 'B2C_1A_PASSWORDCHANGEVWS',
    },
    b2cLoginURL: 'https://cxportalprod.b2clogin.com',
    passwordChangeSuccessUrl: 'https://vws.cdsvisual.net/changePasswordSuccess',
    },
	cxPortal: {
      baseUrl:'https://smartlink.opwvws.com'
    
  },
  fpx: {

  },
  cds: {
    endpoint: 'production',
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
