<div class="app-container">
  <app-header-section *ngIf="msalService.isOnline()"></app-header-section>
  <!-- <app-header *ngIf="msalService.isOnline()"></app-header> -->
  <div class="col-lg-12 body-section">
    <app-alert></app-alert>
    <router-outlet></router-outlet>
    <div class="spinner-container">
      <ngx-spinner bdColor="transparent" size="medium" color="#353839" type="timer">
        <div class="spinner-text">Please wait..</div>
      </ngx-spinner>     
    </div>
    
  </div>
  <app-footer-section></app-footer-section>
</div>