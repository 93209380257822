import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss']
})
export class ButtonGroupComponent implements OnInit {
  @Input() optionData = [];
  @Input() groupName: string;
  @Output() valueChange = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onSelectionChange(item) {
    item.value = 1;
    this.valueChange.emit(item);
  }
}
