import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@cpq-app/shared/services/permissions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from '@cpq-app/shared/services/alert.service';
import { PersonaSetupService } from '@cpq-app/setup/rbac-setup/persona/persona.service';
import { AppConstant } from '@cpq-app/shared/constants/app-constants';
import { forkJoin } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OrganizationService } from '@cpq-app/adminstration/organization/organization.service';
import { GuestConfigService } from '@cpq-app/adminstration/guest-config/guest-config.service';
import { country } from '../country';
import { state } from '../state';
import { cities } from '../cities';
import { MSALService } from '@cpq-app/shared/services/msal.service';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { HeaderService } from '@cpq-app/header/header.service';
import { HeaderComponent } from 'dist/launchpad-iot-dynamic-forms/lib/FormElements/Header.component';
import { addressPattern, emailPattern, firstnamePattern, namePattern, titlePattern, zipcodePattern } from '@cpq-app/regex';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public countries;
  public states;
  public cities;
  statesList;
  citiesList;
  personaList = [];
  organizationList = [];
  organization;
  fpxPartnerId;
  userDetail: UntypedFormGroup;
  userData;
  public countryAttrValue = '';
  public stateAttrValue = '';
  public cityAttrValue = '';

  constructor(private permissionSvc: PermissionsService,
    private alertService: AlertService,
    private personaService: PersonaSetupService,
    private organizationService: OrganizationService,
    private fb: UntypedFormBuilder,
    private guestConfigService: GuestConfigService,
    private toastr: ToastrService,
    private msalService: MSALService,
    private userService: UsersService,
    private spinner: NgxSpinnerService,
    private orgService: OrganizationService,
    private cartService: CartService,
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.countries = country;
    this.states = state;
    this.cities = cities;
    this.getUserProfile();
    this.createProfileForm();
  }

  getUserProfile() {
    this.spinner.show();
    const loggedInUserAdInfo = this.msalService.getUser().idToken;
    this.userService.getUser(loggedInUserAdInfo.oid).subscribe((res) => {
      this.spinner.hide();
      if (res['statusCode'] !== 200) {
        this.alertService.error(
          `${res['message']}`
        );
      } else {
        this.userData = res['data'];
        if (
          this.userData === null ||
          typeof this.userData === typeof undefined
        ) {
          console.log('user is super admin');
        } else {
          this.userData = res['data'];
          this.updateProfileForm();
        }
      }
    });
  }

  createProfileForm() {
    this.userDetail = this.fb.group({
      title: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(titlePattern),
        ]),
      ],

      firstName: [
        this.userData?.firstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(firstnamePattern),
        ]),
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(namePattern),
        ]),
      ],
      userName: [
        {
          value: '',
          disabled: true
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      email: [
        {
          value: '',
          disabled: true
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      address1: [
        '',
        Validators.compose([
          Validators.minLength(2),
          Validators.pattern(addressPattern),
        ]),
      ],
      address2: [
        '',
        Validators.pattern(addressPattern),
      ],
      country: [''],
      state: [''],
      city: [''],
      zipcode: [
        '',
        Validators.compose([
          Validators.pattern(zipcodePattern),
        ]),
      ]
    });

  }

  updateProfileForm() {
    this.userDetail = this.fb.group({
      title: [
        this.userData?.title,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(titlePattern),
        ]),
      ],

      firstName: [
        this.userData?.firstName,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(firstnamePattern),
        ]),
      ],
      lastName: [
        this.userData?.lastName,
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(namePattern),
        ]),
      ],
      userName: [
        {
          value: this.userData.userName,
          disabled: true,
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      email: [
        {
          value: this.userData.email,
          disabled: true,
        },
        Validators.compose([
          Validators.required,
          Validators.pattern(emailPattern),
        ]),
      ],
      address1: [
        this.userData?.address1,
        Validators.compose([
          Validators.minLength(2),
          Validators.pattern(addressPattern),
        ]),
      ],
      address2: [
        this.userData?.address2,
        Validators.pattern(addressPattern),
      ],
      country: [''],
      state: [''],
      city: [''],
      zipcode: [
        this.userData?.zipcode,
        Validators.compose([
          Validators.pattern(zipcodePattern),
        ]),
      ]
    });
    const indexCountry = this.countries.findIndex(
      (x) => parseInt(x.id) === parseInt(this.userData.countryId)
    );
    const indexState = this.states.findIndex(
      (x) => parseInt(x.id) === parseInt(this.userData.stateId)
    );
    this.userDetail.controls['country'].setValue(this.countries[indexCountry], {
      onlySelf: true,
    });
    this.onCountrySelect(this.countries[indexCountry]);
    this.userDetail.controls['state'].setValue(this.states[indexState], {
      onlySelf: true,
    });
    this.onStateSelect(this.states[indexState]);
    this.userDetail.controls['city'].setValue(this.userData.city);
  }

  onCountrySelect(country) {
    this.statesList = [];
    this.citiesList = [];
    this.userDetail.get('state').patchValue('');
    this.userDetail.get('city').patchValue('');
    this.statesList = this.states.filter(function (s) {
      return parseInt(s.country_id) === parseInt(country.id);
    });
  }
  onStateSelect(state) {
    this.citiesList = [];
    this.userDetail.get('city').patchValue('');
    this.citiesList = this.cities.filter(function (i) {
      return parseInt(i.state_id) === parseInt(state.id);
    });
  }

  onCitySelect(city) { }

  getUserAndHeaderDetails(loggedInUserOid) {
    const userDetails = this.userService.getUser(loggedInUserOid);
    return userDetails;
  }

  updateUser() {
    const formdata = this.userDetail.getRawValue();
    const obj = this.userData;
    obj['title'] = formdata.title;
    obj['firstName'] = formdata.firstName;
    obj['lastName'] = formdata.lastName;
    obj['username'] = formdata.userName;
    obj['email'] = formdata.email;
    obj['address1'] = formdata.address1;
    obj['address2'] = formdata.address2;
    obj['zipcode'] = formdata.zipcode;
    obj['countryId'] = parseInt(formdata.country.id);
    obj['stateId'] = parseInt(formdata.state.id);
    obj['city'] = formdata.city;
    obj['ownEmail'] = formdata.email;
    obj['countryName'] = formdata.country.name;
    obj['stateName'] = formdata.state.name;
    obj['countryId'] = parseInt(formdata.country.id);
    obj['stateId'] = parseInt(formdata.state.id);
    this.editUserFunction(obj, obj.Id);
  }

  editUserFunction(obj, id) {
    if (obj.isFpxUser === true) {
      const userObj = {};
      userObj['FirstName'] = obj.firstName;
      userObj['LastName'] = obj.lastName;
      userObj['Username'] = obj.cpqUserName;
      userObj['Email'] = obj.email;
      userObj['Title'] = obj.title;
      userObj['Country'] = obj.countryName;
      userObj['City'] = obj.city;
      userObj['PostalCode'] = obj.zipcode;
      userObj['State'] = obj.stateName;
      userObj['IsActive'] = obj.isActive;
      if (obj.address1.trim()) {
        userObj['Street'] = obj.address1 + ((obj.address2.trim()) ? `, ${obj.address2}` : "");
      } else {
        userObj['Street'] = ((obj.address2.trim()) ? `${obj.address2}` : "");
       }
      this.spinner.show();
      this.cartService
        .updateObjectById(CpqObjects.User,obj['fpxUserId'], userObj)
        .subscribe((resultId) => {
          this.spinner.hide();
          if (resultId) {
            this.editUserInPlatform(obj, id);
          } else {
            // this.alertService.error(resultId);
          }
        }, err => {
          this.spinner.hide();
          this.alertService.error(err.error[0]["context"]['error']);
        });
    } else {
      this.editUserInPlatform(obj, id);
    }
  }

  editUserInPlatform(userObj, userId) {
    this.spinner.show();
    this.userService.EditProfile(userObj).subscribe((response: any) => {
      if (response.statusCode === 200) {
        this.spinner.hide();
        let userName = userObj['firstName'] + ' ' + userObj['lastName'];
        this.headerService.setUsername(userName);
        this.toastr.info('User profile updated successfully.')
      } else {
        this.spinner.hide();
        this.toastr.error('User profile updation failed.')
      }
    }, err => {
      this.toastr.error('User profile updation failed.');
      this.spinner.hide();
    });
  }
}
