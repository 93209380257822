import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@cpq-environments/environment';

@Component({
  selector: 'app-header-middle',
  templateUrl: './header-middle.component.html',
  styleUrls: ['./header-middle.component.scss']
})
export class HeaderMiddleComponent implements OnInit {
  headerLogoUrl = environment.cxPortal.baseUrl;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  openMenuDevice() {
    const divMain = document.getElementById('mainSecondHeader');
    const divContainer = document.getElementById('containerSecondHeader');
    if (divContainer.style.display === 'contents') {
      divContainer.style.display = 'none';
      divMain.style.display = 'none';
    } else {
      divContainer.style.display = 'contents';
      divMain.style.display = 'contents';
    }
  }

  homePage() {
    this.router.navigate(['/']);
  }
}
