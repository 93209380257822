import { OnInit, Directive, ViewContainerRef, TemplateRef, OnDestroy, ElementRef } from "@angular/core";
import { Subscription } from 'rxjs';
import { UsersService } from '@cpq-app/adminstration/users/users.service';

@Directive({
  selector: '[hideFromCustomer]'
})
export class HideFromCustomerDirective implements OnInit, OnDestroy {

  customerViewSubscription: Subscription;

  constructor(
    private userService: UsersService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<ElementRef>,
  ) { }

  public ngOnInit() {
    this.subscribeToCustomerView();
  }

  subscribeToCustomerView() {
    this.customerViewSubscription = this.userService.getCustomerView()
      .subscribe({
        next: isActive => {
          (isActive) ? this.viewContainerRef.clear() : this.viewContainerRef.createEmbeddedView(this.templateRef);
        },
        complete: () => this.subscribeToCustomerView(),
      });
  }

  public ngOnDestroy(): void {
    this.customerViewSubscription?.unsubscribe();
  }
}
