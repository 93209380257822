import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService, CpqObjects, CpqQueryObjects } from '@cpq-app/services/cart.service';
import { SharedService } from '@cpq-app/shared/services/shared.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { VwsErrorMessages } from '@vws-app/VWS';
import { VwsCpqQuote } from '@vws-app/VWS.interfaces.service';
import { addDays } from 'date-fns';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {  Subscription } from 'rxjs';

const DEFAULT_EXPIRATION_DAYS = 30;

@Component({
  selector: 'app-create-revision',
  templateUrl: './create-revision.component.html',
  styleUrls: ['./create-revision.component.scss'],
  providers: [CurrencyPipe]
})
export class CreateRevisionComponent implements OnInit, OnDestroy {

  model: NgbDateStruct;
  revisionForm: UntypedFormGroup;
  submitted = false;
  revisionID: string;
  jobID: string;
  editRevisionForm = false;
  minExpirationDate: {
    year: number,
    month: number,
    day: number,
  };
  quoteLines: any;
  validationMessage: string;
  readonly DEFAULT_CURRENCY_CODE = 'USD'; // FIXME replace default currency code symbol with injected value
  currencySymbol = getCurrencySymbol(this.DEFAULT_CURRENCY_CODE, 'narrow');
  subscription$: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.subscription$.push(this.route.params.subscribe(params => {
      if (params) {
        this.jobID = params.jobID;
        if (params?.revisionID) {
          this.revisionID = params.revisionID;
          this.editRevisionForm = true;
        }
      }
    }));

    this.subscription$.push(this.cartService.updateQuoteFromStepper$?.subscribe({
      next: () => this.updateRevision()
    }));
  }

  ngOnInit(): void {
    const minDate = addDays(new Date(), DEFAULT_EXPIRATION_DAYS);
    this.minExpirationDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate(),
    };
    this.initialFormData();
    if (this.editRevisionForm) {
      this.updateFormData();
    }
  }

  initialFormData() {
    const nonWhiteSpaceRegExp: RegExp = new RegExp('\\S');
    this.revisionForm = this.formBuilder.group({
      Name: [null, [Validators.required, Validators.maxLength(225), Validators.pattern(nonWhiteSpaceRegExp)]],
      ExpirationTime: [this.minExpirationDate],
      Description: [null, Validators.maxLength(2000)],
      Note: [null, Validators.maxLength(2000)],
      SiteVoltage__c: [null, Validators.required],
      SiteDriveSide__c: [null, Validators.required],
      TaxAmount__c: [0, [Validators.min(0)]],
      TaxRequired__c: ['false'],
      TotalAmount:[null]
    });
    this.datePickerValidation();
  }

  updateFormData() {
    this.subscription$.push(this.cartService.getCpqObjects<VwsCpqQuote>(
      CpqQueryObjects.Quote,
      {
        Id: this.revisionID,
      })
      .subscribe({
        next: (quote: any) => { // FIXME quote typing

          this.currencySymbol = getCurrencySymbol(quote.CurrencyIsoCode || this.DEFAULT_CURRENCY_CODE, 'narrow');

          const date = this.updateDate(quote?.ExpirationTime);
          this.revisionForm.controls.ExpirationTime.setValue(date);

          this.revisionForm.controls.Name.setValue(quote?.Name);
          this.revisionForm.controls.TotalAmount.setValue(this.currencyPipe.transform(quote?.TotalAmount));
        

          this.revisionForm.controls.Note.setValue(quote?.Note);
          this.revisionForm.controls.Description.setValue(quote?.Description);
          this.revisionForm.controls.SiteVoltage__c.setValue(quote?.SiteVoltage__c);
          this.revisionForm.controls.SiteDriveSide__c.setValue(quote?.SiteDriveSide__c);

          const taxAmount = this.currencyPipe.transform(quote?.TaxAmount__c, quote?.CurrencyIsoCode || this.DEFAULT_CURRENCY_CODE, '');
          this.revisionForm.controls.TaxAmount__c.setValue(parseFloat((taxAmount?.includes(',')) ? taxAmount?.replace(/,/g, '') : taxAmount));
          if (String(quote?.TaxRequired__c) === 'true' || String(quote?.TaxRequired__c) === 'false') {
            this.revisionForm.controls.TaxRequired__c.setValue(String(quote?.TaxRequired__c));
          }
          this.quoteLines = quote?.QuoteLines;
          this.datePickerValidation();
          this.spinner.hide();
        },
        error: err => {
          this.spinner.hide();
          this.toastr.error(VwsErrorMessages.GENERIC, 'Error', {
            disableTimeOut: true,
            closeButton: true
          });
        }
      }));
  }


  datePickerValidation() {
    this.subscription$.push(this.revisionForm.controls.ExpirationTime.valueChanges.subscribe(data => {
      const dateSelected = data?.year + '-' + data?.month + '-' + data?.day;
      const d = new Date(dateSelected);
      const date = new Date();
      this.validationMessage = (data?.year !== undefined && (d.getTime() < date.getTime())) ? 'Expiration Date must be a current date or future date' : 'Enter a valid Date';
    }));
  }


  dateFormatter(date) {
    const dateSelected = `${date.year}/${date.month}/${date.day}`;
    const d = new Date(dateSelected);
    // removing the timezone offset.
    d.setHours(0, -d.getTimezoneOffset(), 0, 0);
    return d.toISOString();
  }

  updateDate(date) {
    if (date) {
      const [year, month, day] = date.split('-');
      const obj = {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        day: parseInt(day.split(' ')[0].trim(), 10)
      };
      return obj;
    }
  }

  updateRevision() {
    if (!this.isTaxRequired()) {
      this.revisionForm.controls.TaxAmount__c.setValue(0);
    }
    const formGroup = this.sharedService.trimFormFieldValues(this.revisionForm);
    const formValues = formGroup.value;
    delete formValues.ExpirationTime;
    delete formValues.TotalAmount;
    const copyRevisionForm = Object.assign({}, formValues);
    this.revisionForm.updateValueAndValidity();
    if (this.revisionForm.controls['TaxAmount__c'].valid && this.revisionForm.controls['Name'].valid) {
      this.subscription$.push(this.cartService.updateObjectById(CpqObjects.Quote, this.revisionID, copyRevisionForm).subscribe({
        next: () => {
          this.router.navigate(['/job', this.jobID]);
        }
      }));
    } else {
      let errorMsg = VwsErrorMessages.REQUIREDFIELD;
      if (this.revisionForm.controls['TaxAmount__c'].hasError('min')) {
        errorMsg = VwsErrorMessages.MINFIELD;
      }
      this.toastr.error(errorMsg, 'Error', {
        disableTimeOut: true,
        closeButton: true
      });
      return
    }

  }

  isTaxRequired(): boolean {
    // Incoming value may be undefined, boolean, or string.
    return String(this.revisionForm?.controls?.TaxRequired__c?.value).toLowerCase() === 'true';
  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }
}
