import { sequence } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { nonWhiteSpaceRegExp, numberPattern } from '@cpq-app/regex';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
const internal = 'Internal'
const external = 'External'
@Component({
  selector: 'app-edit-library',
  templateUrl: './edit-library.component.html',
  styleUrls: ['./edit-library.component.scss']
})
export class EditLibraryComponent implements OnInit {
  @Input() quote: any;
  preConfiguredQuote: FormGroup;
  SUBMIT_SPINNER = 'submitSpinner';
  quoteInformation;
  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
  ) { }

  ngOnInit(): void {
    this.initialFormData();
  }

  initialFormData() {
    this.preConfiguredQuote = this.formBuilder.group({
      Name: [null, [Validators.required, Validators.pattern(nonWhiteSpaceRegExp),]],
      Sequence__c: [null],
      Scope__c: [null]
    });
    this.quoteInformation = JSON.parse(this.quote);
    if (this.quote) {
      this.preConfiguredQuote.controls.Name.setValue(this.quoteInformation?.name);
      this.preConfiguredQuote.controls.Sequence__c.setValue(this.quoteInformation?.sequence);
      this.preConfiguredQuote.controls.Scope__c.setValue(this.quoteInformation?.scope);
    }
    this.applySequenceValidation();
  }

  applySequenceValidation() {
    this.preConfiguredQuote.get('Sequence__c').setValidators([Validators.max(this.quoteInformation?.length + 1), Validators.required, Validators.min(1), Validators.pattern(numberPattern)]);
    this.preConfiguredQuote.get('Sequence__c').updateValueAndValidity();
  }

  onSave() {
    if (this.preConfiguredQuote.valid) {      
      let payload = this.preConfiguredQuote.value;
      if(this.preConfiguredQuote.value.Scope__c !== null){
        payload.Scope__c = this.preConfiguredQuote.value.Scope__c === true ? JSON.stringify([internal]) : JSON.stringify([internal, external]);
      }
      this.spinner.show();
      this.cartService.updatePreconfiguredQuotes(this.quoteInformation?.id, payload).subscribe({
        next: res => {
          this.spinner.hide();
          this.activeModal.dismiss(res);
        }
      })
    }
  }
}
