import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService, CpqObjects } from '@cpq-app/services/cart.service';
import { ProductService, ExecuteResponse } from '@cpq-app/services/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { ConveyorizedTunnelOptions, VwsCpqQuote } from '../VWS.interfaces.service';

@Component({
  selector: 'app-product-results',
  templateUrl: './product-results.component.html',
  styleUrls: ['./product-results.component.scss']
})
export class ProductResultsComponent implements OnInit {
  jobID: string;
  revisionID: string;
  configID: string;
  configSubscription: Subscription;
  productsData: any;    // FIXME: type
  filteredProducts: any[] = [];    // FIXME: type
  generalSubscription: Subscription;
  searchString: string;
  loadingSearchResults = false;
  enableNoMatchFound = false;
  quoteLines: VwsCpqQuote[];

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private cartService: CartService
  ) {
    this.generalSubscription = this.route.params.subscribe(params => {
      if (params?.jobID) {
        this.jobID = params.jobID;
      }
    });
  }

  ngOnInit(): void {
    this.getProductResultsData();
  }

  onDestroy() {
    this.generalSubscription.unsubscribe();
  }

  getProductResultsData() {
    this.spinner.show();
    this.cartService.getProducts().subscribe((res: any) => {
      this.productsData = res;
      this.applyFilters();
      // this.spinner.hide();
    }, err => {
      console.log(err);
      // this.spinner.hide();
    })
  }

  navigateToConfigPage(aProduct) {
    this.router.navigate([
      this.jobID,
      this.revisionID,
      aProduct,
      'choose',
      'configuration']);
  }

  onClickConfigure(product) {
    const obj = {
      Product__c: product.Product__c
    }
    this.spinner.show();
    const payload = {
      Name: `Copy of ${product?.Name}`,
      OpportunityId: this.jobID
    }
    this.cartService.copyObjectById(CpqObjects.Quote, product?.Id, payload).subscribe({
      next: (results) => {
        this.revisionID = results.Id;
        this.navigateToConfigPage(product.Product__c);
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
      }
    });
  }

  copyNewRecord(sourceQuote: VwsCpqQuote) {
    const newQuote = Object.assign({}, sourceQuote);
    const date = new Date();
    const ISOFormate = date.toISOString().split('.')[0] + 'Z';
    newQuote.Id = null;
    newQuote.LastModifiedDate = ISOFormate;
    newQuote.CreatedDate = ISOFormate;
    newQuote.IsPrimary = 0;
  }

  enableExpertMode(): Observable<ExecuteResponse> {
    return this.productService.setExpertMode(this.configID, true);
  }

  applyFilters() {
    try {
      const searchStr = this.searchString?.trim().toLowerCase() || '';
      this.filteredProducts = this.productsData?.filter(item => item?.Name.toLowerCase().indexOf(searchStr) > -1)
        .map(x => {
          x.imageUrl = this.getImage(x.Image__c);
          return x;
        })
        .sort((a, b) => {
          const aD = Number(a.Sequence__c);
          const bD = Number(b.Sequence__c);
          return Number(aD > bD) - Number(aD < bD);
        });
    } finally {
      this.enableNoMatchFound = true;
      this.spinner.hide();
    }
  }

  getImage(imageName: string): string {
    return this.productService.getImageUrl(imageName);
  }
}
