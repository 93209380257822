import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


export interface DeleteModalObject {
  message: string;
  recordID: string;
}

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent {
  @Input() data: DeleteModalObject;
  constructor(
    public activeModal: NgbActiveModal,
  ) {
  }

  closeActiveModel(isDelete) {
    if (isDelete) {
      this.activeModal.close(this.data.recordID);
    } else {
      this.activeModal.close();
    }

  }
}
