import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/services/authguard.service';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

import { TenantRoutingService } from './tenants/tenant-routing.service';

/*======================================== CPQ Related =================================*/
import { LandingPageComponent } from './tenants/TWG/landing-page/landing-page.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordSuccessComponent } from './changePasswordSuccess/changePasswordSuccess.component';
import { LogoutComponent } from './logout/logout.component';
import { CxCpqSsoComponent } from './cx-cpq-sso/cx-cpq-sso.component';


const routes: Routes = [
  {
    path: '',
    children: TenantRoutingService.getTenantRoutes()
  },
  {
    path: 'cx-cpq-auth',
    component: CxCpqSsoComponent
  },
  {
    path: 'cx-cpq/logout',
    component: LogoutComponent,
  },
  {
    path: 'privacy-terms',
    component: PrivacyTermsComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'setup',
    loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule)
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./adminstration/adminstration.module').then((m) => m.AdminModule),
  },
  {
    path: '_admin',
    loadChildren: () =>
      import('./_administration/_administration.module').then((m) => m.AdminModule),
  },
  {
    path: 'changePasswordSuccess',
    component: ChangePasswordSuccessComponent
  },
  {
    path: '**',
    component: PagenotfoundComponent,
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always' ,onSameUrlNavigation: 'reload'})]
})
export class AppRoutingModule { }
