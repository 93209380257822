import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Quote } from '@cpq-app/tenants/Cpq.interfaces.service';
import { UsersService } from '@cpq-app/adminstration/users/users.service';
import { CartService } from '@cpq-app/services/cart.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelpComponent } from '@vws-app/help/help.component';
import { ShareComponent } from '@vws-app/share/share.component';
import { SubmitJobComponent } from '@vws-app/submit-job/submit-job.component';
import { RouteNames } from '@vws-app/VWS';
import { ToastrService } from 'ngx-toastr';
import { CURRENT_RECORD_STATE } from '@vws-app/configuration/configuration.component';
import { CpqOperations } from '@vws-app/cpqOperations.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy {

  jobID: string;
  revisionID: string;
  configID: string;
  isDistributor: boolean;
  canShare: boolean;
  routeName: string;
  routeID: number;
  subscription$: Subscription[] = [];
  isOrderPlaced = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private userService: UsersService,
    private cd: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private toastr: ToastrService,
    private vwsCpqOperations: CpqOperations
  ) {
  }

  ngOnInit(): void {
    this.subscription$.push(this.route.params.subscribe(params => {
      this.jobID = params.jobID;
      this.revisionID = params.revisionID;
      const routeData = this.route.snapshot?.data;
      this.routeName = routeData?.routeName;
      this.initializeData();
    }));

    this.cartService.orderPlaced$.subscribe(res => {
      if(res) {
        this.isOrderPlaced = res;
      }
    })
  }

  initializeData() {
    this.isDistributor = this.userService.checkLoggedInUserIsDistributor();
  }

  isQuoteEditPage() {
    return this.routeName === RouteNames.REVISION_VIEW;
  }

  showToasterMsg() {
    this.toastr.error(
      'There is fatal error while creating Revision', 'Error', {
      disableTimeOut: true,
      closeButton: true
    }
    );
  }

  help() {
    this.modalService.open(HelpComponent, {
      size: 'lg'
    });
  }

  share() {
    const instance = this.modalService.open(ShareComponent, {
      size: 'lg'
    }).componentInstance;
    instance.jobID = this.jobID;
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.subscription$.forEach(sub => sub.unsubscribe());
  }

}
